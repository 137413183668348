import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";

import Box from "@mui/material/Box";
import { styled } from '@mui/material/styles';
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import Main from "@/components/Main";
import NavFrame from "@/components/Nav/NavFrame";
import { BusinessPartnersContext } from "@/context/BusinessPartnersContext";
import useScrollToTop from "@/hooks/useScrollToTop";
import { AppContext } from "@/context/AppContext";
import utils from "@/utils/common";
import { userKey } from "@/constants/constants";
import { ErrorBoundary } from "react-error-boundary";
import { FallbackComponent, errorHandler } from "./AppInsights/AppInsights";
import { BusinessPartnerDetailsContext } from "@/context/BusinessPartnerDetailsContext";

const Layout = ({ appData }) => {
  const controller = new AbortController();


  const [open, setOpen] = React.useState(false);

  const {
    setCountryOptions, // Uses in BPDetails Page and Contact Page
    setPrimaryLanguageOptions,
    setUserData,
    userData,
    setCoinResultStatuses,
    setTypeOptions,
    setUnFilteredTypeOptions
  } = useContext(AppContext);

  const { setIsDunsMarket, setIsEsoSearchEnabled, setEsoCountryOptions } = useContext(BusinessPartnersContext);
  const { setRequiredFields } = useContext(BusinessPartnerDetailsContext);


  useEffect(() => {
    if (!appData) return null;

    const setUIOptions = () => {
      setCountryOptions(appData?.country?.data?.result);
      setIsDunsMarket(appData?.isDunsMarket?.data?.result);
      setPrimaryLanguageOptions(appData?.primaryLanguage?.data?.result);
      setIsEsoSearchEnabled(appData?.isEsoEnabled);
      let typeOptions = appData?.types?.data?.result || [];
      setUnFilteredTypeOptions(typeOptions);
      setRequiredFields(appData?.prospectRequiredFields);
      if (typeOptions.length) {
        let filteredtypeOptions = typeOptions.filter((type) => !type?.restrictedDealerPortal);
        setTypeOptions(filteredtypeOptions);
      }
      if(appData?.coinStatuses) {
        const parsedCoinStatuses = utils.parseJsonString(appData.coinStatuses);
        setCoinResultStatuses(parsedCoinStatuses);
      }

      const { stringValue } = {
        ...(appData?.esoCountries || {}),
      };
      if (!stringValue) {
        return;
      }
      let countryOptions = stringValue?.split(",")?.map((country) => {
        return {
          value: country?.split("|")[0],
          name: country?.split("|")[1],
        };
      });
      if (countryOptions?.length > 0) {
        setEsoCountryOptions(countryOptions);
      }
    };
    setUIOptions();
  }, [appData]);

  useEffect(() => {
    if (!userData || !Object.keys(userData).length) {
      let data = utils.secureGetFromLocalStorage(userKey);
      setUserData(data)
    }
  }, []);

  useScrollToTop();

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, []);


  return (
    <StyledBox>
      <Header open={open} />
      <NavFrame open={open} setOpen={setOpen} />
      <Main>
        <ErrorBoundary FallbackComponent={FallbackComponent} onError={errorHandler}>
          <Outlet />
        </ErrorBoundary>
      </Main>
      <Footer open={open} />
    </StyledBox>
  );
};
const StyledBox = styled(Box)(() => ({
  display: 'flex',
}))

export default Layout;
