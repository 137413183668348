import env from "@beam-australia/react-env";
let apiVersion = env("API_VERSION");
let apiBaseUrl = `${env("API_URL")}v${apiVersion}/`;

const revokeToken = async (accessToken, tokenRealm) => {
    let endpoint = "user/revokeToken";
    try {
        const response = await fetch(`${apiBaseUrl}${endpoint}`, {
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                accessToken: accessToken,
                tokenRealm: tokenRealm,
            },
        });

        if (response.ok) {
            return
        } else {
            const error = new Error(response.status);
            error.statusCode = response.status;
            throw error;
        }

    } catch (err) {
        return err;
    }
};

const apiRevokeToken = {
    revokeToken,
};

export default apiRevokeToken;
