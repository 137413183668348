import { BusinePartnerEntity } from "@/constants/forms";

export const checkMandatoryFields = (item, prospectRequiredFields) => {
  const mappedFields = fetchLocalMapped(item);

  let allFieldsPresent = prospectRequiredFields.every(
    (field) =>
      mappedFields.hasOwnProperty(field) && mappedFields[field]?.trim() !== "",
  );

  return {
    allFieldsPresent,
    mappedFields,
  };
};

const fetchLocalMapped = (item) => {
  return {
    [BusinePartnerEntity.name]: item.name || "",
    [BusinePartnerEntity.legalName]: item.legalName || "",
    [BusinePartnerEntity.vatNumber]: item.vatNumber || "",
    [BusinePartnerEntity.buildingNumber]: item.buildingNumber || "",
    [BusinePartnerEntity.address1Line1]: item.street || "",
    [BusinePartnerEntity.country]: item.country || "",
    [BusinePartnerEntity.address1City]: item.city || "",
    [BusinePartnerEntity.address1PostCode]: item.postCode || "",
    [BusinePartnerEntity.nationalId1]: item.nationalId || "",
    [BusinePartnerEntity.nationalId2]: item.nationalId2 || "",
    [BusinePartnerEntity.nationalId3]: item.nationalId3 || "",
    [BusinePartnerEntity.gcdmId]: item.gcid || "",
    [BusinePartnerEntity.ucid]: item.ucid || "",
    [BusinePartnerEntity.address2PostCode]: item.billingPostalCode || "",
    [BusinePartnerEntity.address2City]: item.billingCity || "",
    [BusinePartnerEntity.address2Country]: item.billingCountry || "",
    [BusinePartnerEntity.address2Line1]: item.billingStreet || "",
    [BusinePartnerEntity.address2BuildingNumber]: item.billingHouseNo || "",
  };
};
