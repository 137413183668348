import { PROSPECT_VALUE } from "@/constants/constants";

const createBusinessPartnerMapper = ({
  name,
  bmw_legalname,
  bmw_vatnumber,
  bmw_vatnotapplicable,
  bmw_representedby,
  bmw_companyhierarchylevel,
  websiteurl,
  parentaccountid,
  bmw_address1buildingnumber,
  address1_line1,
  address1_line2,
  address1_line3,
  address1_postalcode,
  address1_postofficebox,
  address1_city,
  address1_county,
  bmw_country,
  bmw_salesregionid,
  bmw_hasbillingaddress,
  bmw_address2buildingnumber,
  address2_line1,
  address2_line2,
  address2_line3,
  address2_postalcode,
  address2_postofficebox,
  address2_city,
  address2_county,
  address2_country,
  bmw_address2salesregionid,
  bmw_accounttypeid,
  bmw_accountsubtypeid,
  bmw_legalentitytypeid,
  bmw_owningdealerid,
  bmw_owningdealercontactid,
  ownerid,
  bmw_termofcreditid,
  bmw_invoicelanguage,
  vatCloned,
  telephone1,
  bmw_fleetsize,
  bmw_expectedvolume,
  bmw_nsccompanyid,
  bmw_nsccompanyid2,
  bmw_greymarketcheckdate,
  bmw_greymarketcheckperformed,
  bmw_greymarketcheckresult,
  bmw_alldocumentsprovided,
  bmw_gcdmid,
  bmw_gcdmucid,
  bmw_nsccompanyid3
}) => ({
  name,
  legalName: bmw_legalname,
  vatNumber: bmw_vatnotapplicable ? "" : bmw_vatnumber,
  vatNotApplicable: bmw_vatnotapplicable,
  representedBy: bmw_representedby,
  companyHierarchy: bmw_companyhierarchylevel
    ? {
        value: bmw_companyhierarchylevel,
      }
    : null,
  url: websiteurl,
  l2ParentCompany: parentaccountid
    ? {
        id: parentaccountid?.id,
        logicalName: parentaccountid?.logicalName,
        name: parentaccountid?.name,
      }
    : null,
  buildingNumber: bmw_address1buildingnumber,
  address1Line1: address1_line1,
  address1Line2: address1_line2,
  address1Line3: address1_line3,
  address1PostCode: address1_postalcode,
  postbox: address1_postofficebox,
  address1City: address1_city,
  county: address1_county
    ? {
        id: address1_county?.id,
        logicalName: address1_county?.logicalName,
        name: address1_county?.name,
      }
    : null,
  country: bmw_country ? { value: bmw_country } : null,
  salesRegion: bmw_salesregionid
    ? {
        id: bmw_salesregionid?.id,
        logicalName: bmw_salesregionid?.logicalName,
        name: bmw_salesregionid?.name,
      }
    : null,
  hasBillingAddress: bmw_hasbillingaddress,
  address2BuildingNumber: bmw_address2buildingnumber,
  address2Line1: address2_line1,
  address2Line2: address2_line2,
  address2Line3: address2_line3,
  address2PostCode: address2_postalcode,
  address2PostBox: address2_postofficebox,
  address2City: address2_city,
  address2County: address2_county
    ? {
        id: address2_county?.id,
        logicalName: address2_county?.logicalName,
        name: address2_county?.name,
      }
    : null,
  address2Country: address2_country ? { value: address2_country } : null,
  address2SalesRegion: bmw_address2salesregionid
    ? {
        id: bmw_address2salesregionid?.id,
        logicalName: bmw_address2salesregionid?.logicalName,
        name: bmw_address2salesregionid?.name,
      }
    : null,
  type: bmw_accounttypeid
    ? {
        id: bmw_accounttypeid?.id,
        logicalName: bmw_accounttypeid?.logicalName,
        name: bmw_accounttypeid?.name,
      }
    : null,
  subType: bmw_accountsubtypeid
    ? {
        id: bmw_accountsubtypeid?.id,
        logicalName: bmw_accountsubtypeid?.logicalName,
        name: bmw_accountsubtypeid?.name,
      }
    : null,
  legalEntityType: bmw_legalentitytypeid
    ? {
        id: bmw_legalentitytypeid?.id,
        logicalName: bmw_legalentitytypeid?.logicalName,
        name: bmw_legalentitytypeid?.name,
      }
    : null,
  nkamStatus: {
    value: PROSPECT_VALUE,
  },
  owningRetailer: bmw_owningdealerid
    ? {
        id: bmw_owningdealerid?.id,
        logicalName: bmw_owningdealerid?.logicalName,
        name: bmw_owningdealerid?.originalName,
      }
    : null,
  owningRetailerContact: bmw_owningdealercontactid
    ? {
        id: bmw_owningdealercontactid?.id,
        logicalName: bmw_owningdealercontactid?.logicalName,
        name: bmw_owningdealercontactid?.name,
      }
    : null,
  owningKAM: ownerid
    ? {
        id: ownerid?.id,
        logicalName: ownerid?.logicalName,
        name: ownerid?.name,
      }
    : null,
  termOfCredit: bmw_termofcreditid
    ? {
        id: bmw_termofcreditid.value,
        logicalName: "bmw_termofcredit",
        name: bmw_termofcreditid?.name,
      }
    : null,
  invoiceLanguage: bmw_invoicelanguage ? {
    value: bmw_invoicelanguage,
  } : null,
  vatCloned,
  mainPhone: telephone1,
  fleetSize: bmw_fleetsize,
  expectedVolume: bmw_expectedvolume,
  nationalId1: bmw_nsccompanyid,
  nationalId2: bmw_nsccompanyid2,
  greyMarketCheckDate: bmw_greymarketcheckdate,
  greyMarketCheckPerformed: bmw_greymarketcheckperformed,
  greyMarketCheckResult: bmw_greymarketcheckresult,
  allDocumentsProvided: bmw_alldocumentsprovided,
  ...(bmw_gcdmid && {gcdmId: bmw_gcdmid}),
  ...(bmw_gcdmucid && {ucid: bmw_gcdmucid}),
  ...(bmw_nsccompanyid3 && {nationalId3: bmw_nsccompanyid3})
});

export { createBusinessPartnerMapper };
