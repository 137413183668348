import React from "react";
import { convertISODateToDayMonthYear } from "@/utils/convertISODateToDayMonthYear";

import { DATE } from "@/constants/forms";
import { StyledConditionalLink, StyledConditionalTooltip, StyledViewModeContainer, StyledViewModeLabel } from "@/components/Styles/PagesComponentsCommon";
const ViewMode = ({ label, type, url, view, title, testid, isNotAlloedToViewValue }) => {

  const viewMapper = () => {
    if (view && type === DATE) {
      return convertISODateToDayMonthYear(view);
    } else if (view !== undefined && view !== null) {
      return isNotAlloedToViewValue ? "" : view;
    }
  };

  return (
    <StyledViewModeContainer>
      <StyledViewModeLabel>{label}</StyledViewModeLabel>

      {url ? (
        <StyledConditionalLink
          title={viewMapper()}
          url={url}
          testid={testid}
          label={label}
        />
      ) : (
        // doest makes sense to show the value as tooltip title which is visible in UI, changed to retrieve tooltip title from client.
        <StyledConditionalTooltip value={viewMapper()} title={title === undefined || title === "" ? viewMapper() : title} testid={testid} label={label} />
      )}
    </StyledViewModeContainer>
  );
};

export default ViewMode;
