import React from "react";
import AppError from "@/AppError";
import ActionsBar from "@/components/ActionsBar";
import Backdrop from "@/components/Backdrop";
import MainDetails from "@/components/ContactDetails/MainDetails";
import Alert from "@/components/Form/Alert";
import PageActions from "@/components/PageActions";
import PagePoints from "@/components/PagePoints";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AppContext } from "@/context/AppContext";
import { createContactMapper } from "@/mappers/createContactMapper";
import { updateContactMapper } from "@/mappers/updateContactMapper";
import {
  ACCOUNT_LOST_VALUE,
  CREATE,
  DOWNGRADED_VALUE,
  EDIT,
  SUCCESS,
  VIEW,
  canMarketEditContactKey,
} from "@/constants/constants";
import { CONTACT_DEFAULT_VALUES, ContactEntity } from "@/constants/forms";
import { contactRoute } from "@/constants/routes";
import MultiElementFloatingActionBar from "@/components/MultiElementFloatingActionBar";
import { handleApiError } from "@/utils/handleApiError";
import { InfoPanelContact } from "@/components/InfoPanels/InfoPanelContact";
import {
  StyledPageContainer,
  StyledPageMain,
  StyledPageSide,
} from "@/components/Styles/PagesComponentsCommon";
import { ContactDetailsContext } from "@/context/ContactDetailsContext";
import { useContactAndBpDetails, useContactInitialQueries, useGetBPDetails } from "@/services/queries";
import { useCreateOrUpdateContactMutation } from "@/services/mutations";
import { useQueryClient } from "@tanstack/react-query";
import { BusinessPartnerDetailsContext } from "@/context/BusinessPartnerDetailsContext";
import { permissionToEditContact } from "@/helperFunctions/generic";
import { useTranslation } from "react-i18next";

export const ContactDetailsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const controller = new AbortController();
  const { id } = useParams();

  const {
    alertStatus,
    setAlertStatus,
    alertMessage,
    setAlertMessage,
    setBreadcrumbLevel3Text,
    unFilteredTypeOptions,
    userData: {
      [canMarketEditContactKey]: canMarketEditAContact,
    },
    coinResultStatuses,
  } = useContext(AppContext);

  const {
    setDepartmentOptions,
    setEmailBounceBackOptions,
    setJobFunctionOptions,
    setPositionOptions,
    setPrimaryLanguageDefaultOptionFromConfig,
    setPrimaryLanguageOptionsFromConfig
  } = useContext(ContactDetailsContext);

  let [searchParams] = useSearchParams();
  // The business partner ID is stored in the location state. Location state will be lost when opened in new tab. So this is not a ideal solution.
  // when creating a contact from the Business Partner or Contact page
  // Fetch business partner details from context when user opens the contact in new tab.
  const bpid = location.state?.account?.id ? location.state?.account?.id : searchParams.get("bpid") || null;
  const isPrimaryContact = location.state?.isPrimaryContact
    ? location.state?.isPrimaryContact
    : searchParams.get("isPrimaryContact") || false;


  const [isEditable, setIsEditable] = useState(false);
  const [contact, setContact] = useState(null);
  // const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [mode, setMode] = useState(VIEW);
  const [showAlert, setShowAlert] = useState(false);
  const [permissions, setPermissions] = useState(null);
  const [showPrimaryContactDetailsPanel, setShowPrimaryContactDetailsPanel] =
    useState(false);
  const [showContactRolesPanel, setShowContactRolesPanel] = useState(false);
  const [showContractRolesPanel, setShowContractRolesPanel] = useState(false);
  const [showPrimaryAddressPanel, setShowPrimaryAddressPanel] = useState(false);
  const [showProtectedFields, setShowProtectedFields] = useState(false);
  const [apiError, setApiError] = useState({});
  const canSave = true;
  const [bpDetails, setBpDetails] = useState({});

  const queryClient = useQueryClient();

  const contactInitialData = useContactInitialQueries();
  const contactANdBpDetails = useContactAndBpDetails(id, bpid);
  const getBusinessPartnerDetail = useGetBPDetails(bpid);
  const createOrUpdateMutation = useCreateOrUpdateContactMutation();

  const isInitialDataFetched = contactInitialData.every(query => query.isFetched);
  const isContactAndBpDetailsFetched = contactANdBpDetails.every(query => query.isFetched);
  const { t } = useTranslation();

  useEffect(() => {
    if (contactInitialData.some(query => query.isError)) {
      let error = contactInitialData.find(query => query.isError)?.error;
      setApiError(handleApiError(error));
    }
    if (contactInitialData.some(query => query.isSuccess)) {
      const {
        stringValue: defaultPrimaryLanguageOption,
        description,
      } = {
        ...contactInitialData[4]?.data?.data?.result || {},
      };

      const primaryLanguageOptionsFromConfigDesp = description?.split("|");

      setDepartmentOptions(contactInitialData[0]?.data?.data?.result || []);
      setEmailBounceBackOptions(contactInitialData[1]?.data?.data?.result || []);
      setJobFunctionOptions(contactInitialData[2]?.data?.data?.result || []);
      setPositionOptions(contactInitialData[3]?.data?.data?.result || []);
      setPrimaryLanguageDefaultOptionFromConfig(
        defaultPrimaryLanguageOption,
      );
      setPrimaryLanguageOptionsFromConfig(
        primaryLanguageOptionsFromConfigDesp,
      );
    }
  }, [isInitialDataFetched]);

  useEffect(() => {
    if (contactANdBpDetails.some((query) => query.isError)) {
      let error = contactANdBpDetails.find((query) => query.isError)?.error;
      setApiError(handleApiError(error));
    }
    if (contactANdBpDetails.every((query) => query.isSuccess)) {
      const [contactData, bpPermissions] = [
        contactANdBpDetails[0]?.data?.data.result,
        contactANdBpDetails[1]?.data?.data.result,
      ];
      setBreadcrumbLevel3Text(contactData?.fullName);
      setContact({
        ...contactData,
        address1_postalcode: contactData?.postalCode,
      });
      setPermissions(bpPermissions);
    }
  }, [isContactAndBpDetailsFetched, contactANdBpDetails[0].dataUpdatedAt]);

  useEffect(() => {
    if (getBusinessPartnerDetail.isFetching) {
      return;
    }
    if (getBusinessPartnerDetail.isError) {
      let error = getBusinessPartnerDetail.error;
      setApiError(handleApiError(error));
    }
    if (getBusinessPartnerDetail.isSuccess) {
      setBpDetails(getBusinessPartnerDetail.data?.data?.result || {});
    }
  }, [getBusinessPartnerDetail.dataUpdatedAt, getBusinessPartnerDetail.errorUpdatedAt])

  useEffect(() => {
    setIsSaving(createOrUpdateMutation.isPending)
    if (createOrUpdateMutation.isError) {
      if (createOrUpdateMutation.isError) {
        let error = createOrUpdateMutation.error;
        if (error.statusCode === 400 && error.fieldName) {
          setError(
            error.fieldName,
            {
              type: "focus",
              message: error.message,
            },
            { shouldFocus: true },
          );
        }
        else {
          setApiError(handleApiError(error));
        }
      }
    }
    if (createOrUpdateMutation.isSuccess) {
      queryClient.refetchQueries({ queryKey: ["getBusinessPartnerContacts", { id: bpid }] });
      if (mode === EDIT) {
        createAlert(SUCCESS, t("contactDetailsPage.updateAlertMessage"));
        queryClient.invalidateQueries({ queryKey: ["contactDetails", { id: createOrUpdateMutation.variables[2] }] });
        setMode(VIEW);
      } else {
        let id = createOrUpdateMutation.data?.data?.result?.id;
        navigate(contactRoute + id, {
          replace: true,
          state: {
            account: {
              id: bpid,
            },
          },
        });
        setMode(VIEW);
      }
    }
  }, [createOrUpdateMutation.status]);

  const points = [
    {
      name: t("contactDetailsPage.points.details"),
      url: "#details",
    },
  ];

  useEffect(() => {
    if (!id || !bpid) {
      setMode(CREATE);
    }
  }, [id, mode]);

  // Below code is there to populate the fields directly on useForm rather that creating a function to populate values calling setValue for each and every value.
  // The other reason being the fields which accept Object as value is being nulled due to default values prop when using setValue()

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    watch,
    resetField,
    // eslint-disable-next-line no-unused-vars
    formState: { dirtyFields, isDirty }
  } = useForm({
    values: contact
      ? {
        //...contact,
        bmw_position2: contact?.jobFunction?.value,
        bmw_position: contact?.position?.value,
        primaryLanguage: contact?.primaryLanguage,
        salutation: contact.title,
        firstname: contact.firstName,
        lastname: contact.lastName,
        middlename: contact.middleName,
        bmw_secondlastname: contact.secondLastName,
        bmw_department: contact?.department?.value,
        suffix: contact.suffix,
        jobtitle: contact.jobTitle,
        parentcustomerid: contact.parentCustomer,
        bmw_contactdetailssource: contact.dataSource,
        bmw_tobedeletedon: contact.toBeDeletedOn,
        emailaddress1: contact.email,
        bmw_emailbounceback: contact.emailBounceBack?.value,
        bmw_primarylanguageid: contact.primaryLanguage,
        telephone1: contact.businessPhone,
        mobilephone: contact.mobilePhone,
        bmw_optinavailable: contact.dataConsentAvailable,
        bmw_politicallyexposedperson: contact.politicallyExposedPerson,
        bmw_contractsignatory: contact.signatory,
        bmw_contractrecipient: contact.recipient,
        bmw_influencer: contact.influencer,
        bmw_decisionmaker: contact.decisionMaker,
        bmw_fleetmanager: contact.fleetManager,
        bmw_authorisedrepresentative: contact.authorisedRepresentative,
        bmw_ultimatebeneficialowner: contact.ultimateBeneficialOwner,
        bmw_address1buildingnumber: contact.buildingNumber,
        address1_line1: contact.street1,
        address1_line2: contact.street2,
        address1_line3: contact.street3,
        address1_city: contact.city,
        bmw_address1_county: contact.county,
        address1_postalcode: contact.postalCode,
        address1_postofficebox: contact.poBox,
        bmw_country: contact.country?.value,
        bmw_billingcontact: contact.billing,
        bmw_partnership: contact.partnership,
        bmw_salescontact: contact.sales,
        bmw_agreementcontact: contact.agreement,
        bmw_keycontact: contact.keyContact,
        marketingonly: contact.marketing,
        mobilephone1: contact.mobilePhone,
        [ContactEntity.coinCheckDate]: contact?.coinCheckDate,
        [ContactEntity.coinCheckResult]: coinResultStatuses[contact?.coinCheckResult?.name] || contact?.coinCheckResult?.name,
        [ContactEntity.riskLevel]: contact?.coinRiskLevel?.name || "",
      }
      : location.state
        ? {
          ...CONTACT_DEFAULT_VALUES,
          bmw_address1_county: location?.state?.account?.bpMainaddress?.county,
          address1_postalcode:
            location?.state?.account?.bpMainaddress?.postcode,
          bmw_country: location?.state?.account?.bpMainaddress?.country.value,
          parentcustomerid: location?.state?.account,
          bmw_address1buildingnumber:
            location?.state?.account?.bpMainaddress?.buildingNumber,
          address1_line1: location?.state?.account?.bpMainaddress?.street,
          address1_line2:
            location?.state?.account?.bpMainaddress?.address1Line1,
          address1_line3:
            location?.state?.account?.bpMainaddress?.address1Line2,
          address1_postofficebox:
            location?.state?.account?.bpMainaddress?.poBox,
          address1_city: location?.state?.account?.bpMainaddress?.city,
        }
        : {},
  });

  const createAlert = (status, msg) => {
    setShowAlert(true);
    setAlertStatus(status);
    setAlertMessage(msg);
  };

  const saveUpdateContact = async (data) => {
    let mappedData = mode === EDIT ? updateContactMapper(data) : createContactMapper(data);
    createOrUpdateMutation.mutate([mappedData, mode, id]);
  };


  useEffect(() => {
    const giveFullViewContactPermission = () => {
      setShowPrimaryContactDetailsPanel(true);
      setShowProtectedFields(true);
      setShowContractRolesPanel(true);
      setShowContactRolesPanel(bpDetails.nkamStatus.value === ACCOUNT_LOST_VALUE || bpDetails.nkamStatus.value === DOWNGRADED_VALUE ? false : true);
      setShowPrimaryAddressPanel(true);
    };

    const setViewContactDetailsPermission = async () => {
      //	If my Retailer group is not the Owning retailer but is a Designated retailer
      if (isPrimaryContact) {
        if (
          !permissions.retailerGroupIsOwningRetailer &&
          permissions.retailerGroupIsDesignatedRetailer
        ) {
          if (permissions.agencyModelRestricted) {
            //Agency Model 2

            //hide
            setShowPrimaryContactDetailsPanel(false);
            setShowProtectedFields(false);
            setShowContractRolesPanel(false);
            setShowContactRolesPanel(false);
            setShowPrimaryAddressPanel(false);
          } else {
            //Agency Model 1

            //show
            setShowPrimaryContactDetailsPanel(true);
            setShowProtectedFields(true);

            //hide
            setShowContractRolesPanel(false);
            setShowContactRolesPanel(false);
            setShowPrimaryAddressPanel(false);
          }
        } else {
          giveFullViewContactPermission();
        }
      } else {
        //non primary contact
        giveFullViewContactPermission();
      }
    };

    if (permissions) {
      const isEditable = permissionToEditContact(
        permissions?.retailerGroupIsOwningRetailer,
        canMarketEditAContact,
        unFilteredTypeOptions,
        bpDetails?.type,
      );
      setIsEditable(isEditable);
      setViewContactDetailsPermission();
    }
  }, [permissions, isPrimaryContact, bpDetails, unFilteredTypeOptions]);

  // useEffect(() => {
  //   if (Object.keys(dirtyFields).length > 0) {
  //     setCanSave(true);
  //   } else {
  //     setCanSave(false);
  //   }
  // }, [Object.keys(dirtyFields)]);

  useEffect(() => {
    return () => {
      // Clean up function - abort active api calls on unmount
      controller.abort();
    };
  }, []);

  let isLoading = contactInitialData.some(query => query.isFetching) || contactANdBpDetails.some(query => query.isFetching) || getBusinessPartnerDetail.isFetching;

  return (
    <>
      <ActionsBar backButton={false} />

      {contact && mode === VIEW && <InfoPanelContact {...contact} />}

      <StyledPageContainer
        component={mode === VIEW ? "div" : "form"}
        mt={2}
        noValidate
        onSubmit={handleSubmit(saveUpdateContact)}
      >
        <StyledPageMain>
          {(contact || mode !== VIEW) && (
            <MainDetails
              contact={contact}
              control={control}
              mode={mode}
              showPrimaryContactDetailsPanel={showPrimaryContactDetailsPanel}
              showContactRolesPanel={showContactRolesPanel}
              showContractRolesPanel={showContractRolesPanel}
              showPrimaryAddressPanel={showPrimaryAddressPanel}
              showProtectedFields={showProtectedFields}
              watch={watch}
              reset={reset}
              setValue={setValue}
              setShowAlert={setShowAlert}
              resetField={resetField}
              selectedType={bpDetails?.type}
            />
          )}
          <MultiElementFloatingActionBar>
            <PageActions
              entityType="Contact"
              mode={mode}
              reset={reset}
              setMode={setMode}
              isEditable={isEditable}
              canSave={canSave}
            />
          </MultiElementFloatingActionBar>
        </StyledPageMain>

        <StyledPageSide>
          <PagePoints points={points} backButton />
        </StyledPageSide>
      </StyledPageContainer>

      {(isLoading || isSaving) && <Backdrop text={isSaving && t("backdrop.saving")} />}

      {showAlert && alertStatus && (
        <Alert
          message={alertMessage}
          setShowAlert={setShowAlert}
          showAlert={showAlert}
          type={alertStatus}
        />
      )}

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};
