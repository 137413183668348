import {
  CONTRACT_APPROVED,
  CONTRACT_PREPARATION,
  CONTRACT_PRESENTED,
  CONTRACT_REJECTED,
  NOT_TAKEN_UP,
  ACTIVE,
  CONTRACT_SENTFORSALESAPPROVAL,
  CONTRACT_SENTFORFINANCIALAPPROVAL,
  CoinCheckResult_SellingAllowed,
  GreyMarketCheckResult_SellingAllowed,
  Contact_ComplianceChecks_ContractCheck,
  Contract_Coin_ComplianceMessage,
  Contract_GreyMarketCoin_ComplianceMessage,
  BP_ComplianceCheck_ContractCheck,
} from "@/constants/constants";
import apiBPData from "@/services/apiBusinessPartners";
import apiContactsData from "@/services/apiContacts";
import apiUIData from "@/services/apiUI";

// Filters what contract status options are available
export const filterContractStatuses = (
  contractStatus,
  allowedContractStatuses,
  contractStatuses,
) => {
  let allowedStatuses = [];

  if (contractStatus !== undefined) {
    const filteredAllowedStatuses = allowedContractStatuses.find(
      ({ Name }) => Name === contractStatus,
    );

    if (filteredAllowedStatuses?.AllowedStatusMoveToFE) {
      allowedStatuses = filteredAllowedStatuses?.AllowedStatusMoveToFE.map(
        (item) => {
          return {
            name: item.Name,
            value: item.Value,
          };
        },
      );
    }

    if (filteredAllowedStatuses) {
      allowedStatuses.push({
        name: filteredAllowedStatuses?.Name,
        value: filteredAllowedStatuses?.Value,
      });
    }
  } else {
    allowedStatuses = contractStatuses.filter(
      ({ name }) => name === CONTRACT_PREPARATION,
    );
  }
  return allowedStatuses;
};

export const setContRactPermissions = (contractStatus) => {
  let isDisabled = false;
  let ifContractIsEditable = false;
  let disableTimelineNoteCreation = false;

  if (
    contractStatus === NOT_TAKEN_UP ||
    contractStatus === CONTRACT_PREPARATION
  ) {
    isDisabled = false;
    ifContractIsEditable = true;
  } else if (
    contractStatus === CONTRACT_APPROVED ||
    contractStatus === CONTRACT_PRESENTED ||
    contractStatus === CONTRACT_REJECTED ||
    contractStatus === ACTIVE
  ) {
    isDisabled = true;
    ifContractIsEditable = true;
  } else if (
    contractStatus === CONTRACT_SENTFORSALESAPPROVAL ||
    contractStatus === CONTRACT_SENTFORFINANCIALAPPROVAL
  ) {
    disableTimelineNoteCreation = true;
  } else {
    ifContractIsEditable = true;
  }
  return {
    isDisabled: isDisabled,
    ifContractIsEditable: ifContractIsEditable,
    disableTimelineNoteCreation: disableTimelineNoteCreation,
  };
};

export const checkCoinCheck = async (bpId, controller) => {
  try {
    let response = { canSave: true };
    const [bpConfiguration, contractConfiguration] = await Promise.all([
      apiUIData.getCrmConfiguration(
        BP_ComplianceCheck_ContractCheck,
        controller,
      ),
      apiUIData.getCrmConfiguration(
        Contact_ComplianceChecks_ContractCheck,
        controller,
      ),
    ]);

    const bpComplyBoolean = bpConfiguration?.data?.result?.booleanValue;
    const contractComplyBoolean =
      contractConfiguration?.data?.result?.booleanValue;

    // const bpId = location?.state?.account?.id || contract.account.id;

    if (bpComplyBoolean || contractComplyBoolean) {
      const [bpData, bpConfigData, contractConfigData] =
        await Promise.all([
          bpComplyBoolean &&
            apiBPData.getBusinessPartnerDetail(bpId, controller),
          bpComplyBoolean &&
            apiUIData.getCrmConfiguration(
              Contract_GreyMarketCoin_ComplianceMessage,
              controller,
            ),
          !contractComplyBoolean &&
            apiContactsData.searchContactsData("", bpId, 100, 1, controller),
          !contractComplyBoolean &&
            apiUIData.getCrmConfiguration(
              Contract_Coin_ComplianceMessage,
              controller,
            ),
        ]);

      const bpDetails = bpData?.data?.result;
      const bpConfigString = bpConfigData?.data?.result.stringValue;
      const contractConfigString =
        contractConfigData?.data?.result?.stringValue;

      response = formSaveValidation(
        bpDetails,
        bpConfigString,
        contractConfigString,
      );
    }
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const formSaveValidation = (
  bpDetails,
  bpComplyConfigMsg,
  contactComplyMsg,
) => {
  const isCoinCheckValid = bpDetails ? validateCoinCheck(bpDetails) : true;
  const isGMCheckValid = bpDetails ? validateGreyMarketCheck(bpDetails) : true;
  let canSave = false;
  let alertMessage = "";
  if (!isCoinCheckValid || !isGMCheckValid || bpContactsNotComplaint > 0) {
    if (contactComplyMsg || bpComplyConfigMsg) {
      alertMessage =
        (!isCoinCheckValid || !isGMCheckValid) && bpComplyConfigMsg;
    }
    canSave = false;
  } else {
    canSave = true;
  }
  return {
    canSave: canSave,
    alertMessage: alertMessage,
  };
};

const validateCoinCheck = (bpDetails) => {
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0);
  currentDate.setFullYear(currentDate.getFullYear() - 1);
  const coinCheckResult = bpDetails.coinCheckResult;
  const coinCheckDate = Date.parse(bpDetails.coinCheckDate);

  if (isNaN(coinCheckDate) || coinCheckResult === undefined) {
    return false;
  }

  if (
    coinCheckResult.value !== CoinCheckResult_SellingAllowed
  ) {
    return false;
  }

  return true;
};

// validate greymarket logic
const validateGreyMarketCheck = (bpDetails) => {
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0);
  currentDate.setFullYear(currentDate.getFullYear() - 1);

  const greyMarketCheckDate = Date.parse(bpDetails.greyMarketCheckDate);
  const greymarketCheckResult = bpDetails.greyMarketCheckResult;

  if (isNaN(greyMarketCheckDate) || greymarketCheckResult === undefined) {
    return false;
  }

  if (
    greyMarketCheckDate <= Date.parse(currentDate) ||
    greymarketCheckResult.value !== GreyMarketCheckResult_SellingAllowed
  ) {
    return false;
  }

  return true;
};
