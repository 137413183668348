import React, { useContext, useEffect, useState } from "react";
import { businessPartnerRoute } from "@/constants/routes";
import { AppContext } from "@/context/AppContext";
import { ContactDetailsContext } from "@/context/ContactDetailsContext";
import { CONTACT, CREATE, marketCodeKey } from "@/constants/constants";
import {
    EMAIL,
    LOOKUP,
    NAME,
    SELECT,
    SWITCH,
    TEL,
    TEXT,
    ContactEntity,
    DATE
} from "@/constants/forms";
import {
    WARNING,
} from "@/constants/constants";
import { handleApiError } from "@/utils/handleApiError";
import { showCoin, validateCoinCheckContact, getRoleBasedREquiredFields } from "@/helperFunctions/generic";
import utils from "@/utils/common";
import Grid from "@mui/material/Grid";
import CustomControl from "../Form/CustomControl";
import Section from "../Section";
import SectionTitle from "../SectionTitle";
import { useContactMainInitialQueries, useCountyListQuery, filterRequiredFieldsContact } from "@/services/queries";
import { useTranslation } from "react-i18next";

const ContactMainDetails = ({
    contact,
    control,
    mode,
    watch,
    showPrimaryContactDetailsPanel,
    showContactRolesPanel,
    showPrimaryAddressPanel,
    showProtectedFields,
    setValue,
    setShowAlert,
    resetField,
    selectedType,
}) => {
    const { primaryLanguageOptions, countryOptions, coinResultStatuses, typeOptions, userData: { [marketCodeKey]: marketCode } } = useContext(AppContext);
    const {
        departmentOptions,
        emailBounceBackOptions,
        jobFunctionOptions,
        positionOptions,
        primaryLanguageDefaultOptionFromConfig,
        primaryLanguageOptionsFromConfig,
    } = useContext(ContactDetailsContext);
    const { setAlertStatus, setAlertMessage } = useContext(AppContext);
    const filteredPrimaryLanguageOptions = primaryLanguageOptions.filter((x) =>
        primaryLanguageOptionsFromConfig?.includes(x.name),
    );
    const isSignatory = watch(ContactEntity.signatory);
    // eslint-disable-next-line no-unused-vars
    const [apiError, setApiError] = useState({});
    const [requiredFields, setrequiredFields] = useState([{ FieldName: "First Name", SchemaName: "firstname" }]); // sets a minimum required field in case the config is turned off
    const [configRequiredFields, setConfigRequiredFields] = useState([]);
    const [showFields, setShowFields] = useState(false);
    const [isStreetPOBoxCheck, setStreetPOBoxCheck] = useState(false);
    const [countyList, setCountyList] = useState([]);
    const [coinRelevantRoles, setCoinRelevantRoles] = useState({});
    const [roleBasedRequiredFields, setRoleBasedRequiredFields] = useState({});
    const { t } = useTranslation();

    const [
        isCountrySelectedId,
        primaryLanguageValue,
        address1_line1,
        address1_postofficebox,
    ] = watch([
        ContactEntity.country,
        ContactEntity.primaryLanguage,
        ContactEntity.street1,
        ContactEntity.poBox,
    ]);

    const [signatory, recipent, influencer, decisionMaker, fleetManager, authorisedRepresentative, ultimateBeneficialOwner] = watch([
        ContactEntity.signatory,
        ContactEntity.recipient,
        ContactEntity.influencer,
        ContactEntity.decisionMaker,
        ContactEntity.fleetManager,
        ContactEntity.authorisedRepresentative,
        ContactEntity.ultimateBeneficialOwner,
    ]);

    const initialMainData = useContactMainInitialQueries();
    const countyListQuery = useCountyListQuery(isCountrySelectedId, resetField);

    const isInitialDataFetched = initialMainData.every((query) => query.isFetched);

    // uses equlity check to identify if a field is required or not.
    const isRequiredField = (schemaName) =>
        requiredFields.find((f) => f.SchemaName === schemaName) !== undefined;

    //By default all panels should show in CREATE mode. In VIEW and EDIT modes
    //the visibility depends on the user and their relationship with the BP
    const setPanelVis = (permissionControlValue) => {
        if (mode === CREATE) {
            return true;
        } else {
            return permissionControlValue;
        }
    };

    const handleCountryChange = () => {
        resetField(ContactEntity.county, { defaultValue: "" })
    };

    useEffect(() => {
        var filteredOptions = filteredPrimaryLanguageOptions.filter(
            (x) => x.name === primaryLanguageDefaultOptionFromConfig,
        );
        if (filteredOptions.length > 0 && !primaryLanguageValue) {
            let filteredOption = filteredOptions[0];
            setValue(ContactEntity.primaryLanguage, filteredOption);
        }
    }, [filteredPrimaryLanguageOptions]);

    useEffect(() => {
        if (initialMainData.some(query => query.isError)) {
            let error = initialMainData.find(query => query.isError)?.error;
            setApiError(handleApiError(error));
        }

        if (initialMainData.every(query => query.isSuccess)) {
            setRoleBasedRequiredFields(initialMainData[0].data?.data?.result || {});            

            let [sellingNotAllowedMsg] = [initialMainData[1].data];         

            if(initialMainData[3].data?.data?.result?.stringValue) {
                let parsedRelevantContctRoles = utils.parseJsonString(initialMainData[3].data?.data?.result?.stringValue);
                setCoinRelevantRoles(parsedRelevantContctRoles);
            }

            let validatedResponse = validateCoinCheckContact(contact, sellingNotAllowedMsg);

            setShowFields(validatedResponse.showFields);
            if (validatedResponse.errorMessage) {
                setAlertStatus(WARNING);
                setAlertMessage(validatedResponse.errorMessage);
                setShowAlert(true);
            }

            const { booleanValue: streetPOBoxCheck } = initialMainData[2]?.data?.data?.result || {};
            if (streetPOBoxCheck) {
                setStreetPOBoxCheck(true);
            } else {
                setStreetPOBoxCheck(false);
            }
        }
    }, [isInitialDataFetched]);

    useEffect(() => {
        const params = [
            roleBasedRequiredFields,
            {
                [ContactEntity.signatory]: signatory,
                [ContactEntity.recipient]: recipent,
                [ContactEntity.influencer]: influencer,
                [ContactEntity.decisionMaker]: decisionMaker,
                [ContactEntity.fleetManager]: fleetManager,
                [ContactEntity.authorisedRepresentative]: authorisedRepresentative,
                [ContactEntity.ultimateBeneficialOwner]: ultimateBeneficialOwner,
            },
        ];
        let requiredfields = getRoleBasedREquiredFields(...params);
        setrequiredFields(requiredfields);
        setConfigRequiredFields(requiredfields);

    }, [signatory, recipent, influencer, decisionMaker, fleetManager, authorisedRepresentative, ultimateBeneficialOwner, roleBasedRequiredFields]);

    useEffect(() => {
        if (countyListQuery.isError) {
            setApiError(handleApiError(countyListQuery.error));
        }
        if (countyListQuery.isSuccess) {
            setCountyList(countyListQuery.data?.data?.result);
        }
    }, [countyListQuery.dataUpdatedAt, countyListQuery.errorUpdatedAt]);

    useEffect(() => {
        if (isStreetPOBoxCheck) {
            setrequiredFields(
                filterRequiredFieldsContact(
                    configRequiredFields,
                    address1_line1,
                    address1_postofficebox
                ),
            );
        } else {
            setrequiredFields(configRequiredFields);
        }
    }, [address1_line1, address1_postofficebox, configRequiredFields]);

    const canShowCoinSection = () => {
        const params = [
            CONTACT,
            selectedType,
            typeOptions,
            coinRelevantRoles,
            {
                [ContactEntity.signatory]: signatory,
                [ContactEntity.recipient]: recipent,
                [ContactEntity.influencer]: influencer,
                [ContactEntity.decisionMaker]: decisionMaker,
                [ContactEntity.fleetManager]: fleetManager,
                [ContactEntity.authorisedRepresentative]: authorisedRepresentative,
                [ContactEntity.ultimateBeneficialOwner]: ultimateBeneficialOwner,
            },
            marketCode,
        ];
        return showCoin(...params);
    }
    return (
        <>
            <Section id="details">
                <SectionTitle category={true} title="Main Details" />
            </Section>
            <Section>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <CustomControl
                            control={control}
                            label={t("contactDetailsPage.mainDetails.mainDetails.salutation")}
                            mode={mode}
                            name={ContactEntity.title}
                            type={TEXT}
                            view={contact?.title}
                            required={isRequiredField("salutation")}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <CustomControl
                            control={control}
                            label={t("contactDetailsPage.mainDetails.mainDetails.firstName")}
                            mode={mode}
                            name={ContactEntity.firstName}
                            required={true}
                            type={NAME}
                            view={contact?.firstName}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <CustomControl
                            control={control}
                            label={t("contactDetailsPage.mainDetails.mainDetails.middleName")}
                            mode={mode}
                            name={ContactEntity.middleName}
                            type={NAME}
                            required={isRequiredField("middlename")}
                            view={contact?.middleName}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <CustomControl
                            control={control}
                            label={t(
                                "contactDetailsPage.mainDetails.mainDetails.secondLastName",
                            )}
                            mode={mode}
                            name={ContactEntity.secondLastName}
                            required={isRequiredField("bmw_secondlastname")}
                            type={NAME}
                            view={contact?.secondLastName}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <CustomControl
                            control={control}
                            label={t("contactDetailsPage.mainDetails.mainDetails.lastName")}
                            mode={mode}
                            name={ContactEntity.lastName}
                            required={true}
                            type={NAME}
                            view={contact?.lastName}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        {departmentOptions.length > 0 && (
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.mainDetails.department",
                                )}
                                mode={mode}
                                name={ContactEntity.department}
                                required={isRequiredField("bmw_department")}
                                options={departmentOptions}
                                type={SELECT}
                                view={contact?.department?.name}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <CustomControl
                            control={control}
                            label={t("contactDetailsPage.mainDetails.mainDetails.suffix")}
                            mode={mode}
                            name={ContactEntity.suffix}
                            type={TEXT}
                            view={contact?.suffix}
                            required={isRequiredField("suffix")}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <CustomControl
                            control={control}
                            label={t("contactDetailsPage.mainDetails.mainDetails.jobTitle")}
                            mode={mode}
                            name={ContactEntity.jobTitle}
                            required={isSignatory || isRequiredField("jobtitle")}
                            shouldUnregister={isSignatory}
                            type={TEXT}
                            view={contact?.jobTitle}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        {jobFunctionOptions.length > 0 && (
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.mainDetails.jobFunction",
                                )}
                                mode={mode}
                                name={ContactEntity.jobFunction}
                                options={jobFunctionOptions}
                                required={isRequiredField("bmw_position2")}
                                type={SELECT}
                                view={contact?.jobFunction?.name}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        {positionOptions.length > 0 && (
                            <CustomControl
                                control={control}
                                label={t("contactDetailsPage.mainDetails.mainDetails.position")}
                                mode={mode}
                                name={ContactEntity.position}
                                options={positionOptions}
                                type={SELECT}
                                view={contact?.position?.name}
                                required={isRequiredField("bmw_position")}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <CustomControl
                            control={control}
                            disabled
                            label={t(
                                "contactDetailsPage.mainDetails.mainDetails.accountName",
                            )}
                            mode={mode}
                            name={ContactEntity.parentCustomer}
                            options={[]}
                            required={isRequiredField("parentcustomerid")}
                            type={LOOKUP}
                            view={contact?.parentCustomer?.name}
                            url={businessPartnerRoute + contact?.parentCustomer?.id}
                        />
                    </Grid>

                    {
                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.mainDetails.winContact",
                                )}
                                mode={mode}
                                helperText="e.g. Marketing, Direct referral, Business introduction, Email, Phone"
                                name={ContactEntity.dataSource}
                                required={isRequiredField("bmw_contactdetailssource")}
                                type={TEXT}
                                view={contact?.dataSource}
                            />
                        </Grid>
                    }
                    {mode !== CREATE && showProtectedFields && (
                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                disabled
                                label={t(
                                    "contactDetailsPage.mainDetails.mainDetails.deletedOn",
                                )}
                                mode={mode}
                                name={ContactEntity.toBeDeletedOn}
                                type="date"
                                view={contact?.toBeDeletedOn}
                                required={isRequiredField("bmw_tobedeletedon")}
                            />
                        </Grid>
                    )}
                </Grid>
            </Section>
            {setPanelVis(showPrimaryContactDetailsPanel) && (
                <Section>
                    <SectionTitle title="Primary contact info" />

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.primaryContactInfo.businessEmail",
                                )}
                                mode={mode}
                                name={ContactEntity.email}
                                required={isRequiredField("emailaddress1")}
                                type={EMAIL}
                                view={contact?.email}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            {emailBounceBackOptions.length > 0 && (
                                <CustomControl
                                    control={control}
                                    disabled
                                    label={t(
                                        "contactDetailsPage.mainDetails.primaryContactInfo.emailBounceBack",
                                    )}
                                    mode={mode}
                                    name={ContactEntity.emailBounceBack}
                                    options={emailBounceBackOptions}
                                    type={SELECT}
                                    view={contact?.emailBounceBack?.name}
                                    required={isRequiredField("bmw_emailbounceback")}
                                />
                            )}
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            {primaryLanguageOptions.length > 0 && (
                                <CustomControl
                                    control={control}
                                    label={t(
                                        "contactDetailsPage.mainDetails.primaryContactInfo.primaryLanguage",
                                    )}
                                    mode={mode}
                                    name={ContactEntity.primaryLanguage}
                                    options={filteredPrimaryLanguageOptions}
                                    type={LOOKUP}
                                    view={contact?.primaryLanguage?.name}
                                    required={isRequiredField("bmw_primarylanguageid")}
                                />
                            )}
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.primaryContactInfo.businessLandLine",
                                )}
                                mode={mode}
                                name={ContactEntity.businessPhone}
                                type={TEL}
                                view={contact?.businessPhone}
                                required={isRequiredField("telephone11")}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.primaryContactInfo.businessMobile",
                                )}
                                mode={mode}
                                name={ContactEntity.mobilePhone}
                                type={TEL}
                                view={contact?.mobilePhone}
                                required={isRequiredField("mobilephone1")}
                            />
                        </Grid>
                    </Grid>
                </Section>
            )}
            {setPanelVis(showContactRolesPanel) && (
                <Section>
                    <SectionTitle
                        title={t("contactDetailsPage.mainDetails.contactRole.sectionTitle")}
                    />

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.contactRole.authorisedSignatory",
                                )}
                                mode={mode}
                                name={ContactEntity.signatory}
                                type={SWITCH}
                                view={contact?.signatory}
                                required={isRequiredField("bmw_contractsignatory")}
                                disabled={!showFields}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t("contactDetailsPage.mainDetails.contactRole.recipent")}
                                mode={mode}
                                name={ContactEntity.recipient}
                                type={SWITCH}
                                view={contact?.recipient}
                                required={isRequiredField("bmw_contractrecipient")}
                                disabled={!showFields}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.contactRole.influencer",
                                )}
                                mode={mode}
                                name={ContactEntity.influencer}
                                type={SWITCH}
                                view={contact?.influencer}
                                required={isRequiredField("bmw_influencer")}
                                disabled={!showFields}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.contactRole.decisionMaker",
                                )}
                                mode={mode}
                                name={ContactEntity.decisionMaker}
                                type={SWITCH}
                                view={contact?.decisionMaker}
                                required={isRequiredField("bmw_decisionmaker")}
                                disabled={!showFields}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.contactRole.fleetManager",
                                )}
                                mode={mode}
                                name={ContactEntity.fleetManager}
                                type={SWITCH}
                                view={contact?.fleetManager}
                                required={isRequiredField("bmw_fleetmanager")}
                                disabled={!showFields}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.contactRole.authorizedRepresentative",
                                )}
                                mode={mode}
                                name={ContactEntity.authorisedRepresentative}
                                type={SWITCH}
                                view={contact?.authorisedRepresentative}
                                required={isRequiredField("bmw_authorisedrepresentative")}
                                disabled={!showFields}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.contactRole.ultimateBeneficialOwner",
                                )}
                                mode={mode}
                                name={ContactEntity.ultimateBeneficialOwner}
                                type={SWITCH}
                                view={contact?.ultimateBeneficialOwner}
                                required={isRequiredField("bmw_ultimatebeneficialowner")}
                                disabled={!showFields}
                            />
                        </Grid>
                    </Grid>
                </Section>
            )}
            {canShowCoinSection() && (
                <Section>
                    <SectionTitle title={t("coinSection.title")} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                disabled={true}
                                label={t("coinSection.coinSectionFields.coinDate")}
                                mode={mode}
                                name={ContactEntity.coinCheckDate}
                                type={DATE}
                                view={contact?.coinCheckDate}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                disabled={true}
                                label={t("coinSection.coinSectionFields.coinResult")}
                                mode={mode}
                                name={ContactEntity.coinCheckResult}
                                type={TEXT}
                                view={
                                    coinResultStatuses[contact?.coinCheckResult?.name] ||
                                    contact?.coinCheckResult?.name
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                disabled={true}
                                label={t("coinSection.coinSectionFields.riskLevel")}
                                mode={mode}
                                name={ContactEntity.riskLevel}
                                type={TEXT}
                                view={contact?.coinRiskLevel?.name}
                            />
                        </Grid>
                    </Grid>
                </Section>
            )}
            {setPanelVis(showPrimaryAddressPanel) && (
                <Section>
                    <SectionTitle
                        title={t(
                            "contactDetailsPage.mainDetails.businessAddress.sectiontitle",
                        )}
                    />

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.businessAddress.street",
                                )}
                                mode={mode}
                                name={ContactEntity.street1}
                                type={TEXT}
                                required={isRequiredField("address1_line1")}
                                view={contact?.street1}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.businessAddress.houseNumber",
                                )}
                                mode={mode}
                                name={ContactEntity.buildingNumber}
                                type={TEXT}
                                view={contact?.buildingNumber}
                                required={isRequiredField("bmw_address1buildingnumber")}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.businessAddress.addressLine1",
                                )}
                                mode={mode}
                                name={ContactEntity.street2}
                                type={TEXT}
                                view={contact?.street2}
                                required={isRequiredField("address1_line2")}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.businessAddress.addressLine2",
                                )}
                                mode={mode}
                                name={ContactEntity.street3}
                                type={TEXT}
                                view={contact?.street3}
                                required={isRequiredField("address1_line3")}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.businessAddress.postCode",
                                )}
                                mode={mode}
                                name={ContactEntity.postalCode}
                                type={TEXT}
                                maxLength={10}
                                view={contact?.postalCode}
                                required={isRequiredField("address1_postalcode")}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t("contactDetailsPage.mainDetails.businessAddress.city")}
                                mode={mode}
                                name={ContactEntity.city}
                                type={TEXT}
                                view={contact?.city}
                                required={isRequiredField("address1_city")}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.businessAddress.poBox",
                                )}
                                mode={mode}
                                name={ContactEntity.poBox}
                                type={TEXT}
                                view={contact?.poBox}
                                required={isRequiredField("address1_postofficebox")}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            {countryOptions.length > 0 && (
                                <CustomControl
                                    control={control}
                                    label={t(
                                        "contactDetailsPage.mainDetails.businessAddress.country",
                                    )}
                                    mode={mode}
                                    name={ContactEntity.country}
                                    options={countryOptions}
                                    type={SELECT}
                                    view={contact?.country?.name}
                                    required={isRequiredField("bmw_country")}
                                    onChange={() => handleCountryChange()}
                                />
                            )}
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                label={t(
                                    "contactDetailsPage.mainDetails.businessAddress.county",
                                )}
                                mode={mode}
                                name={ContactEntity.county}
                                type={LOOKUP}
                                options={countyList}
                                view={contact?.county?.name}
                                required={isRequiredField("address1_county")}
                            />
                        </Grid>
                    </Grid>
                </Section>
            )}
            {mode !== CREATE && (
                <Section>
                    <SectionTitle
                        title={t("contactDetailsPage.mainDetails.ids.sectionTitle")}
                    />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                disabled
                                label={t("contactDetailsPage.mainDetails.ids.ucid")}
                                mode={mode}
                                name={ContactEntity.ucid}
                                type={TEXT}
                                view={contact?.ucid}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <CustomControl
                                control={control}
                                disabled
                                label={t("contactDetailsPage.mainDetails.ids.gcid")}
                                mode={mode}
                                name={ContactEntity.gcdmId}
                                type={TEXT}
                                view={contact?.gcdmId}
                            />
                        </Grid>
                    </Grid>
                </Section>
            )}
        </>
    );
};

export default ContactMainDetails;
