/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import Alert from "@/components/Form/Alert";
import AppError from "@/AppError";
import Backdrop from "@/components/Backdrop";
import Connections from "@/components/BusinessPartnerDetails/Connections";
import ContactsPanel from "@/components/BusinessPartnerDetails/ContactsPanel";
import Contracts from "@/components/BusinessPartnerDetails/Contracts";
import DesignatedRetailers from "@/components/BusinessPartnerDetails/DesignatedRetailers";
import MainDetails from "@/components/BusinessPartnerDetails/MainDetails";
import Orders from "@/components/BusinessPartnerDetails/Orders";
import PageActions from "@/components/PageActions";
import PagePoints from "@/components/PagePoints";
import PrintConfirmationButton from "@/components/BusinessPartnerDetails/PrintConfirmationButton";
import Timeline from "@/components/BusinessPartnerDetails/Timeline";
import EligibleDomains from "@/components/BusinessPartnerDetails/EligibleDomains";
import DiscountCategories from "@/components/BusinessPartnerDetails/DiscountCategories";
import apiBPData from "@/services/apiBusinessPartners";
import { AppContext } from "@/context/AppContext";
import { BusinessPartnerDetailsContext } from "@/context/BusinessPartnerDetailsContext";
import {
  canMarketCreateContractKey,
  canMarketEditBPKey,
  canMarketCreateContactKey,
  CREATE,
  EDIT,
  SUCCESS,
  WARNING,
  VIEW,
  SUSPECT_VALUE,
  PROSPECT_VALUE,
  LEVEL2_VALUE,
  marketCodeKey,
  LEAD_VALUE,
} from "@/constants/constants";
import { BP_DEFAULT_VALUES } from "@/constants/forms";
import { businessPartnerRoute } from "@/constants/routes";
import { handleApiError } from "@/utils/handleApiError";
import { createBusinessPartnerMapper } from "@/mappers/createBusinessPartnerMapper";
import { updateBusinessPartnerMapper } from "@/mappers/updateBusinessPartnerMapper";
import MultiElementFloatingActionBar from "@/components/MultiElementFloatingActionBar";
import { InfoPanel } from "@/components/InfoPanels/InfoPanel";
import { StyledPageContainer, StyledPageMain, StyledPageSide } from "@/components/Styles/PagesComponentsCommon";
import BusinessPartnerRoles from "@/components/BusinessPartnerDetails/BusinessPartnerRoles";
import { getComplianceErrorMessage, getTermsOfCreditOption, getVerifiedPermissions, setPrimaryAndNonPrimaryContacts, shouldPrintConfirmationButtonVisibile } from "@/helperFunctions/businessPartners";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useBPInitialQueries, useFetchBPCOmplianceChecks, useFetchBpPermissions, useFetchNkamStatusOptions, useFetchOwnRetailerData, useGetBPContacts, useGetBPDetails, useBPRequiredFields } from "@/services/queries";
import { useAccountTypeChanged } from "@/services/queries";
import Dialog from "../components/Dialog";
import {
  BusinePartnerEntity
} from "@/constants/forms";
import { useTranslation } from "react-i18next";
import { isBPFetched, isFleetContractAllowed } from "@/helperFunctions/generic";
import StringToHTML from "@/components/StringToHTML";
export const BusinessPartnerDetailsPage = () => {
  // get the setBreadcrumbLevel3Text from the AppContext
  const { setBreadcrumbLevel3Text } = useContext(AppContext);
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient()
  const controller = new AbortController();
  const { t } = useTranslation();
  const {
    alertStatus,
    setAlertStatus,
    alertMessage,
    setAlertMessage,
    userData: {
      [canMarketCreateContractKey]: canMarketCreateAContract,
      [canMarketCreateContactKey]: canMarketCreateAContact,
      [canMarketEditBPKey]: canMarketEditABP,
      [marketCodeKey]: marketCode,
    },
    unFilteredTypeOptions,
    typeOptions,
    coinResultStatuses,
  } = useContext(AppContext);

  const {
    nkamStatusOptions,
    printConfirmationTemplatesExists,
    showTimeline,
    setOwningKamServiceUsers,
    legalEntityTypeOptions,
    setShowTimeline,
    setCompanyHierarchyOptions,
    setDowngradeReasonOptions,
    setNkamStatusOptions,
    setLegalEntityTypeOptions,
    setPrintConfirmationTemplatesExists,
    setSalesRegionOptions,
    setInvoiceLanguageOptions,
    setOwningRetailerOptions,
    owningRetailerOptions,
    setGreyResultOptions,
    setAllowedLegalEntityTypeOptions,
    requiredFields,
    setRequiredFields
  } = useContext(BusinessPartnerDetailsContext);

  const [businessPartner, setBusinessPartner] = useState({});
  const [filteredNkamOptions, setFilteredNkamOptions] = useState([
    ...nkamStatusOptions,
  ]);
  const [primaryContacts, setPrimaryContacts] = useState([]);
  const [nonPrimaryContacts, setNonPrimaryContacts] = useState([]);
  const [permissions, setPermissions] = useState(null);
  const [owningRetailer, setOwningRetailer] = useState(null);
  const [termofcreditoptions, settermofcreditoptions] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [mode, setMode] = useState(VIEW);
  const [showAlert, setShowAlert] = useState(false);
  const [showPrintConfirmation, setShowPrintConfirmation] = useState(true);
  const [apiError, setApiError] = useState({});
  const [restrictConfirmationTemplates, setRestrictConfirmationTemplates] =
    useState(false);
  const [updateTimeline, setUpdateTimeline] = useState(false);
  const [showContactsPanel, setShowContactsPanel] = useState(true);
  const [eligibleDomains, setEligibleDomains] = useState([]);
  const [showEligibleDomains, setShowEligibleDomains] = useState(false);
  const [bpContactHasSignatory, setBpContactHasSignatory] = useState(false);
  const [hasOwningRetailerNotFound, setHasOwningRetailerNotFound] = useState(false);
  const [showPrimaryContacts, setShowPrimaryContacts] = useState(false);
  const [showNonPrimaryContacts, setShowNonPrimaryContacts] = useState(false);
  const [showRetailerOfferingNotes, setShowRetailerOfferingNotes] = useState(false);
  const [showContracts, setShowContracts] = useState(false);
  const canSave = true;
  const [open, setOpen] = useState(false);
  const [shouldDisableStatus, setshouldDisableStatus] = useState(false);
  const [primaryContactError, setPrimaryContactError] = useState("");
  const [gcidMissingMessage, setGcidMissingMessage] = useState("");
  const [isGCDMEnabled, setIsGCDMEnabled] = useState(false);
  const [dialogMsg, setDialogmsg] = useState("");
  const [isAccountTypeChanged, setIsAccountTypeChanged] = useState(false);
  const [allowedStatuses, setAllowedStatuses] = useState([]);
  const [isGlobusOrComplyMissing, setIsGlobusOrComplyMissing] = useState(false);
  const [alComplyChecksResults, setAllComplyCheckResults] = useState({
    contactComplyBoolean: false,
    globusStatusBoolean: false,
    bpComplyBoolean: false,
    globusMissMsg: "",
    bpComplyErrorMessage: "",
  });
  const { control, handleSubmit, resetField, setValue, watch, setError, register, formState: { dirtyFields } } =
    useForm({
      // eslint-disable-next-line no-extra-boolean-cast
      values: Boolean(Object.keys(businessPartner).length)
        ? {
          // ...businessPartner,
          name: businessPartner.name,
          bmw_vatnumber: businessPartner.vatNumber,
          bmw_vatnotapplicable: businessPartner.vatNotApplicable,
          bmw_legalname: businessPartner.legalName,
          bmw_companyhierarchylevel: businessPartner.companyHierarchy?.value,
          websiteurl: businessPartner.url,
          parentaccountid: businessPartner.l2ParentCompany,
          bmw_address1buildingnumber: businessPartner.buildingNumber,
          address1_line1: businessPartner.address1Line1,
          address1_line2: businessPartner.address1Line2,
          address1_line3: businessPartner.address1Line3,
          address1_postalcode: businessPartner.address1PostCode,
          address1_postofficebox: businessPartner.postbox,
          address1_city: businessPartner.address1City,
          address1_county: businessPartner.county ? { id: businessPartner.county?.id, logicalName: businessPartner.county?.logicalName, name: businessPartner.county?.name } : null,
          bmw_country: businessPartner.country?.value,
          bmw_salesregionid: businessPartner.salesRegion ? {
            id: businessPartner.salesRegion?.id,
            logicalName: businessPartner.salesRegion?.logicalName,
            name: businessPartner.salesRegion?.name,
          }
            : null,
          bmw_hasbillingaddress: businessPartner.hasBillingAddress,
          bmw_address2buildingnumber: businessPartner.address2BuildingNumber,
          address2_line1: businessPartner.address2Line1,
          address2_line2: businessPartner.address2Line2,
          address2_line3: businessPartner.address2Line3,
          address2_postalcode: businessPartner.address2PostCode,
          address2_postofficebox: businessPartner.address2PostBox,
          address2_city: businessPartner.address2City,
          address2_county: businessPartner.address2County ? { id: businessPartner.address2County?.id, logicalName: businessPartner.address2County?.logicalName, name: businessPartner.address2County?.name } : null,
          address2_country: businessPartner.address2Country?.value,
          bmw_address2salesregionid: businessPartner.address2SalesRegion,
          bmw_accounttypeid: businessPartner.type,
          bmw_accountsubtypeid: businessPartner.subType,
          bmw_legalentitytypeid: businessPartner.legalEntityType,
          bmw_owningdealerid: businessPartner.owningRetailer,
          bmw_owningdealercontactid: businessPartner.owningRetailerContact,
          ownerid: businessPartner.owningKAM,
          bmw_termofcreditid: businessPartner.termOfCredit?.id,
          bmw_invoicelanguage: businessPartner.invoiceLanguage?.value,
          bmw_status: businessPartner.nkamStatus?.value,
          bmw_downgradereason: businessPartner.downgradeReason?.value,
          bmw_downgradedate: businessPartner.downgradeDate,
          bmw_downgradedescription: businessPartner.downgradeDescription,
          bmw_futureprospectdate: businessPartner.futureProspectDate,
          accountnumber: businessPartner.globusId,
          bmw_gcdmucid: businessPartner.ucid,
          bmw_gcdmid: businessPartner.gcdmId,
          bmw_nsccompanyid: businessPartner.nationalId1,
          bmw_nsccompanyid2: businessPartner.nationalId2,
          bmw_nsccompanyid3: businessPartner.nationalId3,
          bmw_registrationoffice: businessPartner.registrationOffice,
          bmw_duns: businessPartner.dunsNumber,
          bmw_totalnoofemployees2: businessPartner.totalNumberOfEmployees,
          bmw_totalnoofactiveretailerroles: businessPartner.totalNumberOfActiveRetailerRoles,
          bmw_expectedvolume: businessPartner.expectedVolume,
          bmw_confirmationofcorporatecustomer: businessPartner.confirmedCorporateCustomer,
          bmw_employeeconfirmation: businessPartner.employeeConfirmation,
          bmw_receivesmotorradterms: businessPartner.receivesMotorradTerms,
          [BusinePartnerEntity.representedBy]: businessPartner.representedBy,
          telephone1: businessPartner.mainPhone,
          bmw_fleetsize: businessPartner.fleetSize,
          [BusinePartnerEntity.coinCheckDate]: businessPartner.coinCheckDate,
          [BusinePartnerEntity.coinCheckResult]: coinResultStatuses[businessPartner.coinCheckResult?.name] || businessPartner.coinCheckResult?.name,
          [BusinePartnerEntity.riskLevel]: businessPartner.coinCheckRiskLevel?.name || "",
          [BusinePartnerEntity.greymarketcheckdate]: businessPartner.greyMarketCheckDate,
          [BusinePartnerEntity.greymarketcheckperformed]: businessPartner.greyMarketCheckPerformed,
          [BusinePartnerEntity.greymarketcheckresult]: businessPartner.greyMarketCheckResult,
          [BusinePartnerEntity.alldocumentsprovided]: businessPartner.allDocumentsProvided,
        } : location?.state?.isFromESO ? {
          ...location.state,
          [BusinePartnerEntity.companyHierarchy]: LEVEL2_VALUE
        }
        : {
          ...BP_DEFAULT_VALUES
        }
    });

  const bpStatus = watch(BusinePartnerEntity.nkamStatus);
  const type = watch(BusinePartnerEntity.type);
  const legalEntityType = watch(BusinePartnerEntity.legalEntityType);
  const hasBillingAddress = watch(BusinePartnerEntity.hasBillingAddress);
  const nationalId2 = watch(BusinePartnerEntity.nationalId2);
  const getRequiredFieldsQuery = useBPRequiredFields(bpStatus, type?.id, legalEntityType?.id, hasBillingAddress);

  let fleetContractAllowed = isFleetContractAllowed(businessPartner?.type, typeOptions);
  const initialPoints =
    mode === VIEW
      ? [
        {
          name: t("businessPartnerDetailPage.pagePoints.details"),
          url: "#details",
        },
        {
          name: t("businessPartnerDetailPage.pagePoints.contacts"),
          url: "#contacts",
        },
        {
          name: t("businessPartnerDetailPage.pagePoints.contracts"),
          url: "#contracts",
        },
        {
          name: t("businessPartnerDetailPage.pagePoints.discountCategories"),
          url: "#discountcategories",
        },
        {
          name: t("businessPartnerDetailPage.pagePoints.orders"),
          url: "#orders",
        },
        {
          name: t("businessPartnerDetailPage.pagePoints.designatedRetailers"),
          url: "#designatedRetailers",
        },
        {
          name: t("businessPartnerDetailPage.pagePoints.bpRoles"),
          url: "#bpRoles",
        },
        {
          name: t("businessPartnerDetailPage.pagePoints.connections"),
          url: "#connections",
        },
        {
          name: t("businessPartnerDetailPage.pagePoints.timeline"),
          url: "#timeline",
        },
        {
          name: t("businessPartnerDetailPage.pagePoints.eligibleDomains"),
          url: "#eligibledomains",
        },
      ]
      : [
        {
          name: t("businessPartnerDetailPage.pagePoints.details"),
          url: "#details",
        },
      ];

  const [points, setPoints] = useState(initialPoints);

  const isBpDetailsFetched = isBPFetched(businessPartner, id);
  // Start of Initializing Queries
  const getBusinessPartnerDetail = useGetBPDetails(id);
  const getBPContacts = useGetBPContacts(id, businessPartner);
  const initialQueries = useBPInitialQueries(mode, isBpDetailsFetched,marketCode);
  const bpDataAndPermissions = useFetchBpPermissions(id);
  const bpComplianceChecks = useFetchBPCOmplianceChecks(bpStatus);
  const ownRetailerQuery = useFetchOwnRetailerData(
    businessPartner?.owningRetailer?.id,
    hasOwningRetailerNotFound,
    owningRetailerOptions.length,
  );
  const nkamStatusOptionsQuery = useFetchNkamStatusOptions(businessPartner, nkamStatusOptions, mode);
  const getHasAccountTypeChangedQuery = useAccountTypeChanged(businessPartner?.type?.id, type?.id, bpStatus);
  // End of Initializing queries

  const isBpDataAndPermissionsFetching = bpDataAndPermissions.some(
    (query) => query.isFetching,
  );
  const isComplyChecksFetching = bpComplianceChecks.some(
    (query) => query.isFetching,
  );
  const isInitialQueriesFetching = initialQueries.some(query => query.isFetching);
  const createOrUpdateMutation = useMutation({
    mutationFn: async (data) => {
      return mode === EDIT ? apiBPData.updateBusinessPartner(id, data) : apiBPData.createBusinessPartner(data);
    }
  });

  const initialQueriesDataUpdatedAt = initialQueries.map((query) => query.dataUpdatedAt);
  const isInitialQueriesErrorUpdated = initialQueries.map((query) => query.errorUpdatedAt);
  useEffect(() => {
    const isError = initialQueries.some((query) => query.isError);
    if (isError) {
      const error = initialQueries.find((query) => query.isError)?.error;
      setApiError(handleApiError(error));
    }
    if (initialQueries.some((query) => query.isSuccess)) {
      const data = initialQueries.map((query) => query.data);
      setShowTimeline(data[0]?.data?.result);
      setCompanyHierarchyOptions(data[1]?.data?.result || []);
      setDowngradeReasonOptions(data[2]?.data?.result || []);
      setNkamStatusOptions(data[3]?.data?.result || []);
      setLegalEntityTypeOptions(data[4]?.data?.result || []);
      setPrintConfirmationTemplatesExists(data[5]?.data?.result);
      setSalesRegionOptions(data[6]?.data?.result || []);
      setInvoiceLanguageOptions(data[7]?.data?.result || []);
      setOwningRetailerOptions(data[8]?.data?.result || []);
      setOwningKamServiceUsers(data[9]?.data?.result || []);
      setPrimaryContactError(data[10]?.data?.result?.stringValue || "");
      setGcidMissingMessage(data[11]?.data?.result?.stringValue || "");
      setIsGCDMEnabled(data[12]?.data?.result?.booleanValue || false);
      setGreyResultOptions(data[13]?.data?.result || []);
      let legalOptions = data[4]?.data?.result || [];
      let legalOptionsFilter =  legalOptions.filter(x => !(x.restrictedDealerPortal));
      setAllowedLegalEntityTypeOptions(legalOptionsFilter)
    }
  }, [...initialQueriesDataUpdatedAt, ...isInitialQueriesErrorUpdated]);

  useEffect(() => {
    if (bpDataAndPermissions.some((query) => query.isError)) {
      const error = bpDataAndPermissions.find((query) => query.isError)?.error;
      setApiError(handleApiError(error));
      return;
    }
    if (bpDataAndPermissions.every((query) => query.isSuccess)) {
      const [
        bpPermissions,
        bpContactVisibility,
        bpContactHasSignatory,
      ] = bpDataAndPermissions.map((query) => query.data);
      if (location?.state?.createdFromDuns != null) {
        createAlert(
          SUCCESS,
          "Success! Business Partner was created from D&B record successfully",
        );
        navigate(location.pathname, { replace: true });
      }

      setPermissions(bpPermissions?.data?.result);
      setBpContactHasSignatory(bpContactHasSignatory?.data?.result);

      if (bpContactVisibility?.data?.result === false) {
        setShowContactsPanel(false);
        setPoints((current) =>
          current.filter((item) => item.name !== "Contacts"),
        );
      }
    }
  }, [isBpDataAndPermissionsFetching]);

  useEffect(() => {
    if (getBusinessPartnerDetail.isError) {
      const error = getBusinessPartnerDetail.error;
      setApiError(handleApiError(error));
      return;
    }
    if (getBusinessPartnerDetail.isSuccess) {
      const businessPartner = getBusinessPartnerDetail.data?.data?.result;
    
      setBusinessPartner(businessPartner);

      setBreadcrumbLevel3Text(businessPartner.name);

      if (
        businessPartner?.coinSellingNotAllowedMessage &&
        (businessPartner?.coinCheckResult?.value == 174640001 ||
          businessPartner?.greyMarketCheckResult?.value == 174640001)
      ) {
        createAlert(WARNING, businessPartner?.coinSellingNotAllowedMessage);
      }

      setRestrictConfirmationTemplates(
        businessPartner.restrictConfirmationTemplates,
      );

      if (businessPartner?.domains) {
        setEligibleDomains(businessPartner.domains);
      }

      if (businessPartner?.companyHierarchy?.value === LEVEL2_VALUE) {
        setShowEligibleDomains(true);
      } else {
        setPoints((current) =>
          current.filter(
            (item) =>
              item.name !== "Eligible Domains" &&
              item.name !== "Business Partner Roles",
          ),
        );
      }

      // window.scrollTo({ behavior: "smooth", top: 0 });
    }
  }, [getBusinessPartnerDetail.status, getBusinessPartnerDetail.dataUpdatedAt, getBusinessPartnerDetail.errorUpdatedAt])

  useEffect(() => {
    if (getBPContacts.isError) {
      const error = getBPContacts.error;
      setApiError(handleApiError(error));
      return;
    }
    if (getBPContacts.isSuccess) {
      const contacts = getBPContacts?.data?.data?.result;

      if (contacts.length > 0) {
        let filteredContacts = setPrimaryAndNonPrimaryContacts(
          contacts,
          businessPartner.primaryContact?.id,
        );
        setPrimaryContacts(filteredContacts.primaryContacts);
        setNonPrimaryContacts(filteredContacts.nonPrimaryContacts);
      }
    }
  }, [getBPContacts.status, getBPContacts.dataUpdatedAt, getBPContacts.errorUpdatedAt]);

  useEffect(() => {
    if (ownRetailerQuery.isError) {
      setApiError(handleApiError(ownRetailerQuery.error));
    }
    if (ownRetailerQuery.isSuccess) {
      const retailerData = ownRetailerQuery?.data?.data?.result;
      setOwningRetailer({
        id: retailerData?.id,
        logicalName: businessPartner?.owningRetailer?.logicalName,
        name: `${retailerData?.name} - ${retailerData?.retailerNumber}`,
        originalName: businessPartner?.owningRetailer?.name,
      });
    }
  }, [ownRetailerQuery.status, ownRetailerQuery.dataUpdatedAt, ownRetailerQuery.errorUpdatedAt]);

  useEffect(() => {
    if (!businessPartner?.owningRetailer) {
      return;
    }
    if (owningRetailerOptions && businessPartner?.owningRetailer) {
      const retailerFound = owningRetailerOptions.find(
        (retailer) => retailer.id === businessPartner?.owningRetailer.id,
      );
      if (retailerFound) {
        setOwningRetailer({
          id: retailerFound.id,
          logicalName: businessPartner.owningRetailer.logicalName,
          name: `${retailerFound.name} - ${retailerFound.retailerNumber}`,
          originalName: businessPartner.owningRetailer.name,
        });
        setHasOwningRetailerNotFound(false);
      } else {
        setHasOwningRetailerNotFound(true);
      }
    }
  }, [owningRetailerOptions, businessPartner?.owningRetailer]);

  useEffect(() => {
    if (isComplyChecksFetching) {
      return;
    }
    const isError = bpComplianceChecks.some((query) => query.isError);
    if (isError) {
      const error = bpComplianceChecks.find((query) => query.isError)?.error;
      setApiError(handleApiError(error));
    }
    if (bpComplianceChecks.every((query) => query.isSuccess)) {
      let complyObj = {
        contactComplyBoolean: bpComplianceChecks[0].data?.data?.result?.booleanValue,
        globusStatusBoolean: bpComplianceChecks[1].data?.data?.result?.booleanValue,
        bpComplyBoolean: bpComplianceChecks[2].data?.data?.result?.booleanValue,
        globusMissMsg: bpComplianceChecks[3].data?.data?.result?.description,
        bpComplyErrorMessage: bpComplianceChecks[4].data?.data?.result?.stringValue,
      }
      setAllComplyCheckResults(complyObj)
      complianceOrGlobusFunctionality(complyObj);
    }
  }, [isComplyChecksFetching]);

  const complianceOrGlobusFunctionality = (complyObj) => {
    let contactsofBP = [...primaryContacts, ...nonPrimaryContacts];

    let warningMessage = getComplianceErrorMessage(
      businessPartner,
      complyObj
    );

    if (warningMessage) {
      setShowAlert(true);
      setAlertStatus(WARNING);
      setAlertMessage(warningMessage);
      setIsGlobusOrComplyMissing(true);
    } else {
      setIsGlobusOrComplyMissing(false);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (bpStatus === PROSPECT_VALUE && businessPartner?.id && !Boolean(businessPartner?.gcdmId) && isGCDMEnabled) {
      gcidMissingMessage && setShowAlert(true);
      setAlertStatus(WARNING);
      setAlertMessage(gcidMissingMessage);
    }
  }, [businessPartner, bpStatus]);

  useEffect(() => {
    if (getHasAccountTypeChangedQuery.isFetching) return;
    if (getHasAccountTypeChangedQuery.isError) {
      let error = getHasAccountTypeChangedQuery.error
      setApiError(handleApiError(error))
    }
    if (getHasAccountTypeChangedQuery.isSuccess) {
      if (getHasAccountTypeChangedQuery.data?.data?.result?.result) {
      setOpen(getHasAccountTypeChangedQuery.data?.data?.result?.result);      
        setDialogmsg(getHasAccountTypeChangedQuery.data?.data?.result?.message);
        setIsAccountTypeChanged(true);
      }
    }
  }, [getHasAccountTypeChangedQuery.dataUpdatedAt, getHasAccountTypeChangedQuery.errorUpdatedAt, getHasAccountTypeChangedQuery.isFetching])

  useEffect(() => {
    if (id) {
      // getBusinessPartnerWithPermissions(id);
    } else {
      setMode(CREATE);
    }
  }, [id, mode]);

  useEffect(() => {
    let isLegalEntityTypeAllowEditBP = legalEntityTypeOptions.find(x => x.id === businessPartner?.legalEntityType?.id);
    if (permissions) {
      const {
        isPermittedToEditBp,
        isPrimaryContactsVisible,
        isContractsOrDesignatedRetailersVisible,
        isNonPrimaryContactVisible,
      } = getVerifiedPermissions(
        permissions,
        canMarketEditABP,
        businessPartner,
        unFilteredTypeOptions,
        isLegalEntityTypeAllowEditBP
      );
      setIsEditable(isPermittedToEditBp);
      setShowRetailerOfferingNotes(isContractsOrDesignatedRetailersVisible);
      setShowContracts(isContractsOrDesignatedRetailersVisible);
      setShowPrimaryContacts(isPrimaryContactsVisible);
      setShowNonPrimaryContacts(isNonPrimaryContactVisible);

      setPoints((current) => {
        return current.filter((point) => {
          if (
            point.name === "Designated Retailers" ||
            point.name === "Contracts"
          ) {
            return isContractsOrDesignatedRetailersVisible;
          } else if (point.name === "Primary Contacts") {
            return isNonPrimaryContactVisible;
          } else {
            return true;
          }
        });
      });
    }
  }, [permissions, unFilteredTypeOptions, legalEntityTypeOptions]);

  useEffect(() => {
    if (nkamStatusOptionsQuery.isError) {
      setApiError(handleApiError(nkamStatusOptionsQuery.error));
      return;
    }
    if (nkamStatusOptionsQuery.isSuccess) {
      const { integerValue, stringValue } =
        nkamStatusOptionsQuery.data?.data?.result || {};
      const statusValues = stringValue?.split(";") || [];
      statusValues.push(integerValue?.toString());
      const filteredStatuses = nkamStatusOptions.filter((option) =>
        statusValues.includes(option.value.toString()),
      );
      setFilteredNkamOptions(filteredStatuses);
      setAllowedStatuses(statusValues)
    }
  }, [nkamStatusOptionsQuery.status, nkamStatusOptionsQuery.dataUpdatedAt, nkamStatusOptionsQuery.errorUpdatedAt]);

  useEffect(() => {
    if (createOrUpdateMutation.isError) {
      let err = createOrUpdateMutation.error;
      // let fieldname = getFieldNameForError(err);
      if (err.statusCodeValue === 500 && err.fieldName) {
        setError(
          err.fieldName,
          { type: "focus", message: err.message },
          { shouldFocus: true },
        );
      } else {
        setApiError(handleApiError(err));
      }
    }
    if (createOrUpdateMutation.isSuccess) {
      createAlert(
        SUCCESS,
        `Success! Business Partner was ${mode === EDIT ? "updated" : "created"} successfully`,
      );
      if (mode === CREATE) {
        let bpId = createOrUpdateMutation.data.data.id
        navigate(businessPartnerRoute + bpId, { replace: true });
      } else {
        queryClient.invalidateQueries(
          {
            queryKey: ["getBusinessPartnerDetail", { id: id }],
          }
        );
        queryClient.refetchQueries(
          {
            queryKey: ["businessPartners"],
          }
        );
        // bpDataAndPermissions[0].refetch({cancelRefetch: true});
      }
      setMode(VIEW);
    }
  }, [createOrUpdateMutation.isPending]);

  useEffect(() => {
    setShowPrintConfirmation(
      shouldPrintConfirmationButtonVisibile(
        mode,
        printConfirmationTemplatesExists,
        restrictConfirmationTemplates,
      ),
    );
  }, [mode, printConfirmationTemplatesExists, restrictConfirmationTemplates]);

  useEffect(() => {
    if (mode === EDIT) {
      queryClient.invalidateQueries(
        {
          queryKey: ["getBusinessPartnerDetail", { id: id }],
        }
      );
    }
  }, [mode]);

  const createAlert = (status, msg) => {
    setShowAlert(true);
    setAlertStatus(status);
    setAlertMessage(msg);
  };

  useEffect(() => {
    if (getRequiredFieldsQuery.isFetching) return;
    if (getRequiredFieldsQuery.isError) {
      let error = getRequiredFieldsQuery.error
      setApiError(handleApiError(error))
    }
    if (getRequiredFieldsQuery.isSuccess) {
      setRequiredFields(getRequiredFieldsQuery?.data?.data?.result || []);
    }
  }, [getRequiredFieldsQuery.dataUpdatedAt, getRequiredFieldsQuery.errorUpdatedAt, getRequiredFieldsQuery.isFetching]);

  const isRequiredField = (schemaName) =>
    requiredFields.find((name) => name === schemaName);

  const saveUpdateBusinessPartner = async (data) => {
    if(bpStatus === LEAD_VALUE && isGlobusOrComplyMissing) {
      complianceOrGlobusFunctionality(alComplyChecksResults);
      return;
    }
    const termOfCredit = termofcreditoptions.find(
      (x) => x.value === data.bmw_termofcreditid
    );

    data.bmw_termofcreditid = termOfCredit;
    if (bpStatus === SUSPECT_VALUE && !businessPartner.primaryContact && isRequiredField("primarycontactid")) {
      setApiError({
        isError: true,
        message: primaryContactError,
        responseURL: "",
        statusCode: "",
        title: "Add primary contact",
      });
    } else {
      createOrUpdateMutation.mutate(mode === EDIT ? updateBusinessPartnerMapper(dirtyFields, data) : createBusinessPartnerMapper(data));
    }
  };

  const gettermsofcreditoption = async (
    accountType,
    companyHierarchy,
    l2ParentCompany,
  ) => {
    try {
      const result = await getTermsOfCreditOption(
        accountType,
        companyHierarchy,
        l2ParentCompany,
        controller,
      );
      settermofcreditoptions(result.termOfCreditOptions);
      if (result?.selectedTerms?.id) {
        setValue(BusinePartnerEntity.termOfCredit, result?.selectedTerms?.id);
      }
    } catch (err) {
      setApiError(handleApiError(err));
    }
  };

  useEffect(() => {
    if (showTimeline !== undefined && !showTimeline) {
      // Remove timeline anchor link if timeline panel is hidden
      setPoints((current) =>
        current.filter((item) => {
          return item.name !== "Timeline";
        }),
      );
    }
  }, [showTimeline]);

  useEffect(() => {
    if(mode !== VIEW && nationalId2.length === 16 && marketCode === "IT" && initialQueries[14]?.data?.data?.result?.booleanValue) {
      setDialogmsg(initialQueries[14]?.data?.data?.result?.stringValue || "");
      setOpen(true);
      setIsAccountTypeChanged(false);
    }
  }, [nationalId2])

  useEffect(() => {
    return () => {
      // Clean up function - abort active api calls on unmount
      // // queryClient.invalidateQueries()
      // controller.abort();
    };
  }, []);

  const handleClose = () => {
    setOpen(false);
    setValue(BusinePartnerEntity.type, businessPartner?.type);
  }

  const handleOkay = () => {
    setOpen(false);
  }

  const bpMainAddress = {
    buildingNumber: businessPartner?.buildingNumber,
    street: businessPartner?.address1Line1,
    address1Line1: businessPartner?.address1Line2,
    address1Line2: businessPartner?.address1Line3,
    postcode: businessPartner?.address1PostCode,
    pobox: businessPartner?.postbox,
    city: businessPartner?.address1City,
    county: businessPartner?.county,
    country: businessPartner?.country,
  };

  const isFetching = isBpDataAndPermissionsFetching || createOrUpdateMutation.isPending || getHasAccountTypeChangedQuery.isFetching || (mode !== VIEW ? isInitialQueriesFetching : false);
  return (
    <>
      {businessPartner && mode === VIEW && <InfoPanel {...businessPartner} />}
      <StyledPageContainer
        component={mode === VIEW ? "div" : "form"}
        mt={2}
        noValidate
        onSubmit={handleSubmit(saveUpdateBusinessPartner)}
      >
        <StyledPageMain>
          {(businessPartner || mode !== VIEW) && (
            <MainDetails
              businessPartner={businessPartner}
              owningRetailer={owningRetailer}
              control={control}
              filteredNkamOptions={filteredNkamOptions}
              mode={mode}
              permissions={permissions}
              resetField={resetField}
              watch={watch}
              setValue={setValue}
              termofcreditoptions={termofcreditoptions}
              gettermsofcreditoption={gettermsofcreditoption}
              setShowAlert={setShowAlert}
              shouldDisableNKAMStatus={shouldDisableStatus}
              register={register}
              isBpDetailsFetched={isBpDetailsFetched}
              isGCDMEnabled={isGCDMEnabled}
              allowedStatuses={allowedStatuses}
            />
          )}

          {businessPartner && mode === VIEW && (
            <>
              {showContactsPanel &&
                (showNonPrimaryContacts || showPrimaryContacts) && (
                  <ContactsPanel
                    bpid={businessPartner.id}
                    companyHierarchy={businessPartner.companyHierarchy?.value}
                    hasOwningRetailer={businessPartner.owningRetailer}
                    name={businessPartner.name}
                    nonPrimaryContacts={nonPrimaryContacts}
                    permissions={permissions}
                    primaryContacts={primaryContacts}
                    canMarketCreateContacts={canMarketCreateAContact}
                    showPrimaryContacts={showPrimaryContacts}
                    showNonPrimaryContacts={showNonPrimaryContacts}
                    bpMainaddress={bpMainAddress}
                    bpType={businessPartner?.type}
                    isContactsLoading={getBPContacts.isFetching}
                  />
                )}
              {showContracts && (
                <Contracts
                  bpid={businessPartner.id}
                  bpStatus={businessPartner.nkamStatus}
                  canMarketCreate={canMarketCreateAContract}
                  companyHierarchy={businessPartner.companyHierarchy?.value}
                  hasOwningRetailer={businessPartner.owningRetailer}
                  hasDesignatedRetailer={
                    businessPartner.totalNumberOfActiveRetailerRoles
                  }
                  permissions={permissions}
                  name={businessPartner.name}
                  primaryContacts={primaryContacts}
                  bpContactHasSignatory={bpContactHasSignatory}
                  isBpDetailsFetched={isBpDetailsFetched} isFleetContractAllowed={fleetContractAllowed}
                />
              )}
              <DiscountCategories bpid={id} setPoints={setPoints} isBpDetailsFetched={isBpDetailsFetched} />
              <Orders bpid={id} isBpDetailsFetched={isBpDetailsFetched} />
              {showRetailerOfferingNotes && (
                <DesignatedRetailers
                  bpid={id}
                  hasDesignatedRetailer={
                    businessPartner.totalNumberOfActiveRetailerRoles
                  }
                  hasOwningRetailer={businessPartner.owningRetailer}
                  notes={businessPartner?.retailerOfferingNotes}
                  permissions={permissions}
                  isBpDetailsFetched={isBpDetailsFetched}
                />
              )}
              {businessPartner?.companyHierarchy?.value === LEVEL2_VALUE && (
                <BusinessPartnerRoles bpid={id} isBpDetailsFetched={isBpDetailsFetched} bpName={businessPartner.name} />
              )}
              <Connections bpid={id} isBpDetailsFetched={isBpDetailsFetched} />
              {showTimeline && (
                <Timeline
                  id={id}
                  setIsSaving={setIsSaving}
                  setShowAlert={setShowAlert}
                  updateTimeline={updateTimeline}
                  setUpdateTimeline={setUpdateTimeline}
                />
              )}
              {showEligibleDomains && (
                <EligibleDomains eligibleDomains={eligibleDomains} />
              )}
            </>
          )}
          <MultiElementFloatingActionBar>
            <PageActions
              entityType="Business partner"
              mode={mode}
              isEditable={isEditable}
              setMode={setMode}
              canSave={canSave}
            />
            {showPrintConfirmation && (
              <PrintConfirmationButton
                bpid={id}
                setIsLoading={setIsLoading}
                setShowAlert={setShowAlert}
                setUpdateTimeline={setUpdateTimeline}
              />
            )}
          </MultiElementFloatingActionBar>
        </StyledPageMain>

        <StyledPageSide>
          <PagePoints points={points} backButton></PagePoints>
        </StyledPageSide>
      </StyledPageContainer>
      {(isLoading || isSaving || isFetching) && (
        <Backdrop text={isSaving && t("backdrop.saving")} />
      )}

      {showAlert && alertStatus && (
        <Alert
          message={alertMessage}
          setShowAlert={setShowAlert}
          showAlert={showAlert}
          type={alertStatus}
        />
      )}
      <Dialog
        open={open}           
        {...(isAccountTypeChanged ? {handleClose:handleClose}: {})}
        handler={handleOkay}
        id="accountTypeChanged"
        prompt=""      
        title={isAccountTypeChanged ? "Account Type Changed" : ""}
        showDivider={false}
      >
        {isAccountTypeChanged ? dialogMsg : <StringToHTML text={dialogMsg} />}
      </Dialog>

      {apiError?.isError && (
        <AppError apiError={apiError} setApiError={setApiError} />
      )}
    </>
  );
};
