import { EMPTY_GUID } from "@/constants/constants";

const updateBusinessPartnerMapper = (
  dirtyFields,
  {
    name,
    bmw_legalname,
    bmw_vatnumber,
    bmw_vatnotapplicable,
    bmw_representedby,
    bmw_companyhierarchylevel,
    websiteurl,
    parentaccountid,
    bmw_address1buildingnumber,
    address1_line1,
    address1_line2,
    address1_line3,
    address1_postalcode,
    address1_postofficebox,
    address1_city,
    address1_county,
    bmw_country,
    bmw_salesregionid,
    bmw_hasbillingaddress,
    bmw_address2buildingnumber,
    address2_line1,
    address2_line2,
    address2_line3,
    address2_postalcode,
    address2_postofficebox,
    address2_city,
    address2_county,
    address2_country,
    bmw_address2salesregionid,
    bmw_accounttypeid,
    bmw_accountsubtypeid,
    bmw_legalentitytypeid,
    bmw_status,
    bmw_downgradedescription,
    bmw_downgradedate,
    bmw_downgradereason,
    bmw_futureprospectdate,
    bmw_owningdealerid,
    bmw_owningdealercontactid,
    ownerid,
    bmw_nsccompanyid,
    bmw_nsccompanyid2,
    bmw_nsccompanyid3,
    bmw_registrationoffice,
    bmw_expectedvolume,
    bmw_termofcreditid,
    bmw_invoicelanguage,
    bmw_contactdetailssource,
    vatCloned,
    telephone1,
    bmw_fleetsize,
    bmw_greymarketcheckperformed,
    bmw_greymarketcheckdate,
    bmw_greymarketcheckresult,
    bmw_alldocumentsprovided,
  },
) => ({
  name,
  ...(dirtyFields.bmw_legalname && { legalName: bmw_legalname || "" }),
  ...((dirtyFields.bmw_vatnumber || dirtyFields.bmw_vatnotapplicable) && { vatNumber: bmw_vatnotapplicable ? "" : bmw_vatnumber || "" }),
  ...(dirtyFields.bmw_vatnotapplicable && {
    vatNotApplicable: bmw_vatnotapplicable,
  }),
  ...(dirtyFields.bmw_representedby && {
    representedBy: bmw_representedby || "",
  }),
  companyHierarchy: {
    value: bmw_companyhierarchylevel ? bmw_companyhierarchylevel : 0,
  },
  ...(dirtyFields.websiteurl && { url: websiteurl || "" }),
  ...(dirtyFields.parentaccountid && {
    l2ParentCompany:
       {
          id: parentaccountid?.id || EMPTY_GUID,
          logicalName: parentaccountid?.logicalName,
          name: parentaccountid?.name,
        }
      ,
  }),
  ...(dirtyFields.bmw_address1buildingnumber && {
    buildingNumber: bmw_address1buildingnumber || "",
  }),
  ...(dirtyFields.address1_line1 && { address1Line1: address1_line1 || "" }),
  ...(dirtyFields.address1_line2 && { address1Line2: address1_line2 || "" }),
  ...(dirtyFields.address1_line3 && { address1Line3: address1_line3 || "" }),
  ...(dirtyFields.address1_postalcode && {
    address1PostCode: address1_postalcode || "",
  }),
  ...(dirtyFields.address1_postofficebox && {
    postbox: address1_postofficebox || "",
  }),
  ...(dirtyFields.address1_city && { address1City: address1_city || "" }),
  ...(dirtyFields.address1_county && {
    county: {
      id: address1_county?.id || EMPTY_GUID,
      logicalName: address1_county?.logicalName,
      name: address1_county?.name,
    },
  }),
  country: {
    value: bmw_country ? bmw_country : 0,
  },
  ...(dirtyFields.bmw_salesregionid && {
    salesRegion: {
      id: bmw_salesregionid?.id || EMPTY_GUID,
      logicalName: bmw_salesregionid?.logicalName,
      name: bmw_salesregionid?.name,
    },
  }),
  ...(dirtyFields.bmw_hasbillingaddress && {
    hasBillingAddress: bmw_hasbillingaddress || false,
  }),
  ...(dirtyFields.bmw_address2buildingnumber && {
    address2BuildingNumber: bmw_address2buildingnumber || "",
  }),
  ...(dirtyFields.address2_line1 && { address2Line1: address2_line1 || "" }),
  ...(dirtyFields.address2_line2 && { address2Line2: address2_line2 || "" }),
  ...(dirtyFields.address2_line3 && { address2Line3: address2_line3 || "" }),
  ...(dirtyFields.address2_postalcode && {
    address2PostCode: address2_postalcode || "",
  }),
  ...(dirtyFields.address2_postofficebox && {
    address2PostBox: address2_postofficebox || "",
  }),
  ...(dirtyFields.address2_city && { address2City: address2_city || "" }),
  ...(dirtyFields.address2_county && {
    address2County: {
      id: address2_county?.id || EMPTY_GUID,
      logicalName: address2_county?.logicalName,
      name: address2_county?.name,
    },
  }),
  ...(dirtyFields.address2_country && {
    address2Country: {
      value: address2_country ? address2_country : 0,
    },
  }),
  ...(dirtyFields.bmw_address2salesregionid && {
    address2SalesRegion: {
      id: bmw_address2salesregionid?.id || EMPTY_GUID,
      logicalName: bmw_address2salesregionid?.logicalName,
      name: bmw_address2salesregionid?.name,
    },
  }),
  ...(dirtyFields.bmw_accounttypeid && {
    type: {
      id: bmw_accounttypeid?.id || EMPTY_GUID,
      logicalName: bmw_accounttypeid?.logicalName,
      name: bmw_accounttypeid?.name,
    },
  }),
  ...(dirtyFields.bmw_accountsubtypeid && {
    subType: {
      id: bmw_accountsubtypeid?.id || EMPTY_GUID,
      logicalName: bmw_accountsubtypeid?.logicalName,
      name: bmw_accountsubtypeid?.name,
    },
  }),
  ...(dirtyFields.bmw_legalentitytypeid && {
    legalEntityType: {
      id: bmw_legalentitytypeid?.id || EMPTY_GUID,
      logicalName: bmw_legalentitytypeid?.logicalName,
      name: bmw_legalentitytypeid?.name,
    },
  }),
  ...(dirtyFields.bmw_status && {
    nkamStatus: {
      value: bmw_status ? bmw_status : 0,
    },
  }),
  ...(dirtyFields.bmw_downgradereason && {
    downgradeReason: {
      value: Number.isInteger(bmw_downgradereason)
        ? bmw_downgradereason.toString().length > 0
          ? bmw_downgradereason
          : 0
        : 0,
    },
  }),
  ...(dirtyFields.bmw_downgradedate && {
    downgradeDate: bmw_downgradedate || "",
  }),
  ...(dirtyFields.bmw_futureprospectdate && {
      futureProspectDate: bmw_futureprospectdate || "",
  }),
  ...(dirtyFields.bmw_downgradedescription && {
    downgradeDescription: bmw_downgradedescription || "",
  }),
  ...(dirtyFields.bmw_owningdealerid && {
    owningRetailer: {
      id: bmw_owningdealerid?.id || EMPTY_GUID,
      logicalName: bmw_owningdealerid?.logicalName,
      name: bmw_owningdealerid?.originalName,
    },
  }),
  ...(dirtyFields.bmw_owningdealercontactid && {
    owningRetailerContact: {
      id: bmw_owningdealercontactid?.id || EMPTY_GUID,
      logicalName: bmw_owningdealercontactid?.logicalName,
      name: bmw_owningdealercontactid?.name,
    },
  }),
  ...(dirtyFields.ownerid && {owningKAM: {
    id: ownerid?.id || EMPTY_GUID,
    logicalName: ownerid?.logicalName,
    name: ownerid?.name,
  }}),
  ...(dirtyFields.bmw_nsccompanyid && { nationalId1: bmw_nsccompanyid || "" }),
  ...(dirtyFields.bmw_nsccompanyid2 && {
    nationalId2: bmw_nsccompanyid2 || "",
  }),
  ...(dirtyFields.bmw_nsccompanyid3 && {
    nationalId3: bmw_nsccompanyid3 || "",
  }),
  ...(dirtyFields.bmw_registrationoffice && {
    registrationOffice: bmw_registrationoffice || "",
  }),
  ...(dirtyFields.bmw_termofcreditid && {
    termOfCredit: {
      id: bmw_termofcreditid.value || EMPTY_GUID,
      logicalName: "bmw_termofcredit",
      name: bmw_termofcreditid?.name,
    },
  }),
  ...(dirtyFields.bmw_expectedvolume && {
    expectedVolume: bmw_expectedvolume || 0,
  }),
  ...(dirtyFields.bmw_invoicelanguage && {
    invoiceLanguage: {
      value: bmw_invoicelanguage ? bmw_invoicelanguage : 0,
    }
  }),
  ...(dirtyFields.bmw_contactdetailssource && {
    dataSource: bmw_contactdetailssource || "",
  }),
  ...(dirtyFields.vatCloned && { vatCloned }),
  ...(dirtyFields.telephone1 && { mainPhone: telephone1 || "" }),
  ...(dirtyFields.bmw_fleetsize && { fleetSize: bmw_fleetsize || 0 }),
  ...(dirtyFields.bmw_greymarketcheckdate && {
    greyMarketCheckDate: bmw_greymarketcheckdate || "",
  }),
  ...(dirtyFields.bmw_greymarketcheckperformed && {
    greyMarketCheckPerformed:bmw_greymarketcheckperformed
  }),
  ...(dirtyFields.bmw_greymarketcheckresult && {
    greyMarketCheckResult: {      
      value: bmw_greymarketcheckresult?.value ? bmw_greymarketcheckresult.value : 0,
      name: bmw_greymarketcheckresult?.name || ""
    }
  }),
  ...(dirtyFields.bmw_alldocumentsprovided && {
    allDocumentsProvided: bmw_alldocumentsprovided,
  }),
});

export { updateBusinessPartnerMapper };
